import { Stack } from '@mui/material';
import AppButton from 'src/components/common/AppButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { DeleteDialogPropsType } from 'src/types/feature-dialog';

const DeleteDialog = ({ open, onClose, idWord, id, mutate, isDeleting }: DeleteDialogPropsType) => {
  const { t } = useLocales();
  const confirm = useBoolean();

  return (
    <>
      {/* Confirm */}
      <ConfirmDialog
        open={open}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.confirmDeleteMessage')}
        icon={
          <Stack width="100%" direction="row" justifyContent="center">
            <Iconify icon="material-symbols:delete-outline" width={30} />
          </Stack>
        }
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isDeleting}
              onClick={() => mutate({ [idWord]: id })}
            />
          </>
        }
      />
    </>
  );
};

export default DeleteDialog;
