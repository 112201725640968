import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { usePopover } from 'src/components/custom-popover';
import {
  HearingCouncilsQuery,
  useJoinHearingCounciMutation,
  useSubscribeHearingCouncilMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import { error, info, primary } from 'src/theme/palette';
import { HearingCouncilDto } from 'src/types/hearing-council';
import { minutesSinceMidnight } from 'src/utils/minutes-calc-func';
import { sessionButtonLabel } from 'src/utils/sessions/session-button-label';

const useHearingCouncilsCard = ({
  hearingCouncil,
}: {
  hearingCouncil: HearingCouncilsQuery['hearing_councils'][0];
}) => {
  // #region Hooks and State
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const qrDialog = useBoolean();
  const deleteDialog = useBoolean();
  const { t } = useLocales();
  const popover = usePopover();
  const { enqueueSnackbar } = useSnackbar();

  const adminId = JSON.parse(localStorage.getItem('user') || '{}')?.id;

  // #endregion Hooks and State

  // #region Helpers
  const chipColor = (status: 'live' | 'expired' | 'pending') => {
    switch (status.toLowerCase()) {
      case 'live':
        return 'success';
      case 'expired':
        return 'error';
      case 'pending':
        return 'info';
      default:
        return 'warning';
    }
  };

  const isSubscribedToHearingCouncil = hearingCouncil.hearing_councils_user_hearing_councils.some(
    (session) => session.admin?.id === adminId
  );

  const canJoinSession =
    (hearingCouncil.status === 'Pending' || hearingCouncil.status === 'Live') && // Check if the hearing council status is pending
    minutesSinceMidnight(new Date()) >
      hearingCouncil.startTime! - hearingCouncil.appointmentLimit && // Check if the hearing council time began
    minutesSinceMidnight(new Date()) < hearingCouncil.endTime!;

  const isButtonEnabled = canJoinSession;

  const buttonLabel = t(sessionButtonLabel(hearingCouncil.status! as 'Live'));

  // #endregion Helpers

  // #region Mutations
  const { mutate: join, isLoading: isJoining } = useJoinHearingCounciMutation({
    onSuccess: (data) =>
      navigate(hearingCouncil.hearingCouncilId, { state: data.joinHearingCouncil }),
  });

  const { mutate: subscribe, isLoading: isSubscribing } = useSubscribeHearingCouncilMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['HearingCouncils'] });
      enqueueSnackbar('Subscribed successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  // #endregion Mutations

  // #region Context Menu Items
  const CONTEXT_ITEMS = [
    {
      onClick: deleteDialog.onTrue,
      icon: 'material-symbols:delete-outline',
      color: error.light,
      label: t('tableLabels.delete'),
    },
    {
      onClick: qrDialog.onTrue,
      icon: 'fluent:qr-code-28-regular',
      color: primary.main,
      label: t('hearingCouncils.card.qrCode'),
    },
    {
      onClick: () =>
        navigate(
          paths.dashboard.contentManagement.hearingCouncilAnalysis(hearingCouncil.hearingCouncilId)
        ),
      icon: 'uil:analysis',
      color: info.darker,
      label: t('buttons.showAnalysis'),
      disabled: !isButtonEnabled,
    },
  ];

  // #endregion Context Menu Items

  return {
    isButtonEnabled,
    isJoining,
    isSubscribing,
    isSubscribedToHearingCouncil,
    deleteDialog,
    qrDialog,
    buttonLabel,
    subscribe,
    t,
    join,
    adminId,
    navigate,
    chipColor,
    CONTEXT_ITEMS,
    popover,
  };
};

export default useHearingCouncilsCard;
