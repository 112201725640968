import { yupResolver } from '@hookform/resolvers/yup';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Container, Dialog, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useUpdateTranslationMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useSchemas from 'src/schemas/hooks/useSchemas';
import {
  EditDiffWordDialogPropsType,
  EditDiffWordMutationVariablesType,
} from 'src/types/edit-diff-words';

type OptionType = {
  value: string;
  label: string;
};
const EditDiffWordDialog = ({ open, onClose, diffWord }: EditDiffWordDialogPropsType) => {
  if (!open) return <></>;
  // #region States
  const addBookDialog = useBoolean();
  const { t } = useLocales();
  const { categoryId, levelId } = useParams();
  const confirm = useBoolean();
  const { allLangs } = useLocales();
  const queryClient = useQueryClient();
  const [dataToSend, setDataToSend] = useState<EditDiffWordMutationVariablesType>();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const { diffWordEditSchema } = useSchemas();
  // #endregion States

  let defaultValues: EditDiffWordMutationVariablesType = {
    word: diffWord.word || '',
    translatedText: diffWord.translatedText || {},
    translationId: diffWord.translationId || '',
  };

  // #region form
  for (let i = 0; i < allLangs.length && !diffWord; i++) {
    defaultValues.translatedText[allLangs[i].value] = '';
  }

  const methods = useForm<EditDiffWordMutationVariablesType>({
    defaultValues,
    resolver: yupResolver(diffWordEditSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: EditDiffWordMutationVariablesType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = Object.values(errors)
    .map((errors) => Object.keys(errors))
    .flat()
    .filter((value, index, array) => array.indexOf(value) !== index);
  // #endregion form

  // #region Services
  const { mutate, isLoading: isUpdating } = useUpdateTranslationMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['TranslationsByMatnSectionId'] });
      onClose();
    },
  });
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers
  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `translatedText.${currentTabValue}`,
      methods.watch().translatedText[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);
  // #endregion useEffect
  return (
    <>
      <Dialog
        open={open && !confirm.value && !addBookDialog.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {t('dialogs.editDiffWord.header')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <TranslationTabs
              handleChangeTab={handleChangeTab}
              currentTabValue={currentTabValue}
              errors={langsWithErrors}
            />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 0, md: 2 }}>
                <Grid item xs={12}>
                  <Stack spacing={3} py={3}>
                    <Block label={t('dialogs.addNewMatnSection.word')}>
                      <RHFTextField name={`word.${currentTabValue}`} />
                    </Block>
                    <Block label={t('dialogs.addNewMatnSection.itsMeaning')}>
                      <RHFTextField name={`translatedText.${currentTabValue}`} />
                    </Block>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <AppButton
                    label={t('buttons.edit')}
                    fullWidth
                    onClick={() => onSubmit(methods.watch())}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AppButton
                    onClick={onClose}
                    label={t('buttons.cancel')}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </Container>
        </DialogContent>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: t('buttons.edit').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isUpdating}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default EditDiffWordDialog;
