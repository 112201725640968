import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useCreateSessionMutation, useMutoonCategoriesQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddSessionMutationVariablesType, SessionDtoType } from 'src/types/sessions';

const useAddNewSession = () => {
  // #region States
  const confirm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const { sessionSchema } = useSchemas();
  const router = useNavigate();
  const { t, allLangs } = useLocales();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const { state }: { state: SessionDtoType | undefined } = useLocation();
  const isEditing = !!state;
  const [dataToSend, setDataToSend] = useState<AddSessionMutationVariablesType>();
  // #endregion States

  let defaultValues: AddSessionMutationVariablesType = {
    title: state?.title || {},
    description: state?.description || {},
    startDate: state?.startDate || new Date(),
    expiryDate: state?.expiryDate || moment().add(2, 'hours').toDate(),
    mutoonMatnId: state?.mutoon!?.id || '',
    mutoonMatnName: state?.mutoon?.name || '',
    prerequisiteIds: state?.prerequisites
      ? state?.prerequisites.map((prerequisite) => prerequisite.prerequisiteId)
      : [],
    enable_conversation: state?.enable_conversation || true,
    enable_raise_hand: state?.enable_raise_hand || true,
    enable_recording: state?.enable_recording || true,
    enable_upload_files: state?.enable_upload_files || true,
    enable_video: state?.enable_video || true,
    max_user_count: state?.max_user_count || 10,
    frequency: state?.frequency || '',
    days: state?.days || ['Sun'],
    duration: state?.duration || 30,
  };

  // #region form
  for (let i = 0; i < allLangs.length && !state; i++) {
    defaultValues.title[allLangs[i].value] = '';
    defaultValues.description[allLangs[i].value] = '';
  }

  const methods = useForm<AddSessionMutationVariablesType>({
    defaultValues,
    resolver: yupResolver(sessionSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddSessionMutationVariablesType) => {
      const newData = { ...data };
      newData.startDate = moment(newData.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      newData.expiryDate = moment(newData.expiryDate).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      delete (newData as any).mutoonMatnName;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // #endregion form

  // #region Services
  // Create session
  const { mutate: createSession, isLoading: isAdding } = useCreateSessionMutation({
    onSuccess: () => {
      confirm.onFalse();
      enqueueSnackbar({
        variant: 'success',
        message: 'Session has created successfully',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      router(paths.dashboard.contentManagement.sessions);
    },
  });

  // Get mutoon
  const { data: mutoonOptions, isSuccess: hasGotMtns } = useMutoonCategoriesQuery({
    categoryCond: {},
  });

  // const prerequisitesOptions = mutoonOptions?.mutoons.map((prq) => ({
  //   label: prq.name[currentLang.value],
  //   value: prq.id,
  // }));
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`title.${currentTabValue}`, methods.watch().title[currentTabValue] || '');
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);
  // #endregion useEffect

  return {
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    handleChangeTab,
    allLangs,
    langsWithErrors,
    t,
    hasGotMtns,
    mutoonOptions,
    isEditing,
    createSession,
    isAdding,
    dataToSend,
    confirm,
  };
};

export default useAddNewSession;
