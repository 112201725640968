// @mui
import { Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
// components
import Iconify from 'src/components/iconify';
import AppPermissions from 'src/constants/app-permissions';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import ReplyToMessageDialog from 'src/sections/dialogs/edit/reply-to-message-dialog';
import { ContactUsDto } from 'src/types/contact-us';

// ----------------------------------------------------------------------

type Props = {
  row: ContactUsDto;
  deleteHandler: (mutoonId: string) => void;
};

export default function ContactUsRow({ row, deleteHandler }: Props) {
  const { t } = useLocales();
  const dialog = useBoolean();
  const isSolved = row.status === 'unactive' || row.status === 'replied';

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.name}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.email}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <Chip
            color={isSolved ? 'success' : 'warning'}
            label={t(`tables.contactUs.status.${row.status as 'inactive'}`)}
            variant='soft'
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.message}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {moment(row.createdAt).format('YYYY-MM-DD')}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.CONTACT_US_DELETE]}>
            <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.contactId)}>
                <Iconify
                  icon="material-symbols:delete-outline"
                  width="27"
                  height="27"
                  color="#DDB6FF"
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.CONTACT_US_EDIT]}>
            <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink} onClick={dialog.onTrue}>
                <Iconify icon="ic:baseline-reply" width="27" height="27" color="#FFAB8B" />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
      <ReplyToMessageDialog
        onClose={dialog.onFalse}
        open={dialog.value}
        message={{ response: row.response!, contactId: row.contactId }}
      />
    </>
  );
}
