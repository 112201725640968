// @mui
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import AppPermissions from 'src/constants/app-permissions';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { ExamDtoType } from 'src/types/exams';
import { getLocalizedValue } from 'src/utils/get-localized-value';

// ----------------------------------------------------------------------

type Props = {
  row: ExamDtoType;
  deleteHandler: (levelId: string) => void;
};

export default function ExamsRow({ row, deleteHandler }: Props) {
  const { t } = useLocales();
  const { themeMode } = useSettingsContext();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {getLocalizedValue(row.title)}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {getLocalizedValue(row.description)}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {getLocalizedValue(row.mutoon.name)}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.EXAMS_DELETE]}>
            <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.examId)}>
                <Iconify
                  icon="material-symbols:delete-outline"
                  width="27"
                  height="27"
                  color="#DDB6FF"
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.EDIT_QUESTIONS]}>
            <Tooltip title={t('tableLabels.editQuestions')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink}>
                <Link
                  to={`/dashboard/content-management/mutoon/exams/questions/${row.examId}`}
                  state={row}
                  style={{ display: 'flex' }}
                >
                  <Iconify
                    icon="fluent-mdl2:page-edit"
                    width="27"
                    height="27"
                    color="primary.main"
                  />
                </Link>
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.EXAMS_EDIT]}>
            <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink}>
                <Link to={row.examId} state={row} style={{ display: 'flex' }}>
                  <Iconify icon="tabler:edit" width="27" height="27" color="#FFAB8B" />
                </Link>
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
    </>
  );
}
