// @mui
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import AppPermissions from 'src/constants/app-permissions';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { BookDto } from 'src/types/books';
import { getLocalizedValue } from 'src/utils/get-localized-value';

// ----------------------------------------------------------------------

type Props = {
  row: BookDto;
  deleteHandler: (mutoonId: string) => void;
};

export default function ReferenceBooksRow({ row, deleteHandler }: Props) {
  const { t } = useLocales();
  const router = useNavigate();
  const addDialog = useBoolean();
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === 'light';

  const handleEdit = () => {
    router(`${row.bookId}`, { state: { book: row } });
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {getLocalizedValue(row.bookAuthor)}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {getLocalizedValue(row.bookName)}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {moment(row.updatedAt).format('YYYY-MM-DD')}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.BOOKS_EDIT]}>
            <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
              <IconButton onClick={handleEdit}>
                <Iconify icon="fluent-mdl2:page-edit" width="27" height="27" color="#FFAB8B" />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.BOOKS_VIEW]}>
            <Tooltip title={t('tableLabels.preview')} placement="bottom" arrow>
              <IconButton href={row.pdfUrl!} target="_blank" LinkComponent={RouterLink}>
                <Iconify icon="mdi:eye-circle" width="27" height="27" color="#4A8769" />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.BOOKS_DELETE]}>
            <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.bookId)}>
                <Iconify
                  icon="material-symbols:delete-outline"
                  width="27"
                  height="27"
                  color="#DDB6FF"
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
    </>
  );
}
