import { Helmet } from 'react-helmet-async';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppTable from 'src/components/table/app-table';
import { CONNECTED_USERS_TABLE_HEAD, SCHOOL_STUDENTS_TABLE_HEAD } from 'src/constants/tables-heads';
import { ConnectedUser } from 'src/types/hearing-council';
import useConnectedUsers from './use-connected-users';

const ConnectedUsers = ({ connectedUsers }: { connectedUsers: ConnectedUser[] | undefined }) => {
  const { t, table, columns } = useConnectedUsers();

  // Make users unique by email
  const users = connectedUsers?.filter(
    (user, index, self) => index === self.findIndex((t) => t.email === user.email)
  );

  const viewComponent = (
    <>
      <AppTable<ConnectedUser>
        data={users!}
        columns={columns}
        dataCount={connectedUsers!?.length}
        headLabels={CONNECTED_USERS_TABLE_HEAD(t)}
        table={table}
        removePagination
        title={t('hearingCouncils.joinPage.connectedUsers')}
      />
    </>
  );

  return (
    <AppLoadingAndErrorWrapper isLoading={false} sx={{ p: 2 }}>
      {viewComponent}
    </AppLoadingAndErrorWrapper>
  );
};

export default ConnectedUsers;
