import { Container, Stack } from '@mui/material';
import Users from './table/users';

const UserManagementView = () => {
  return (
    <Container>
      <Stack spacing={3}>
        <Users />
      </Stack>
    </Container>
  );
};

export default UserManagementView;
