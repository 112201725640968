import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Card, CardContent, CardHeader, Container, Grid, Tab, Tabs, alpha } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import RHFDatePicker from 'src/components/hook-form/rhf-date-picker';
import { Upload } from 'src/components/upload';
import useAddNewBook from './use-add-new-book';

const AddNewBook = () => {
  const {
    t,
    handleSubmit,
    onSubmit,
    methods,
    isUpdating,
    isUploadingRefBook,
    file,
    handleDropSingleFile,
    setFile,
    editRefBook,
    state,
    currentTabValue,
    handleChangeTab,
    allLangs,
    langsWithErrors,
    isEditing,
    confirm,
    isAdding,
    mutate,
  } = useAddNewBook();

  return (
    <>
      <Container>
        <Helmet>
          <title>{t('titles.addBook')}</title>
        </Helmet>

        <AppLoadingAndErrorWrapper isLoading={false} errorMessage={null} sx={{ height: 'auto' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} display="flex" gap={4} flexDirection="column">
                <Card>
                  <CardHeader title={t('dialogs.addNewBook.uploadPdf')} />
                  <CardContent>
                    <AppLoadingAndErrorWrapper
                      sx={{ height: 'auto' }}
                      isLoading={isUploadingRefBook}
                      errorMessage={null}
                    >
                      <Upload
                        file={file}
                        onDrop={handleDropSingleFile}
                        error={!!methods.formState.errors.pdfUrl}
                        onDelete={() => {
                          setFile(null);
                          methods.setValue('pdfUrl', '');
                          editRefBook({
                            bookId: state!?.book!?.bookId,
                            ...methods.watch(),
                            pdfUrl: '',
                          });
                        }}
                      />
                    </AppLoadingAndErrorWrapper>
                  </CardContent>
                </Card>
                <Block label={t('dialogs.addNewBook.dateOfBorn')}>
                  <RHFDatePicker name="dateOfBorn" />
                </Block>
                <Block label={t('dialogs.addNewBook.dateOfDeath')} required={false}>
                  <RHFDatePicker name="dateOfDeath" />
                </Block>
                <Block label={t('dialogs.addNewBook.numberOfLines')}>
                  <RHFTextField name="numberOfLines" type="number" />
                </Block>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Tabs
                  value={currentTabValue}
                  onChange={handleChangeTab}
                  sx={{
                    px: 3,
                    boxShadow: (theme) =>
                      `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                  }}
                >
                  {allLangs!?.map((tab) => (
                    <Tab
                      key={tab.value}
                      value={tab.value}
                      label={tab.label}
                      sx={{
                        color: langsWithErrors.includes(tab.value)
                          ? 'red !important'
                          : 'text.primary',
                      }}
                    />
                  ))}
                </Tabs>
                <Grid container py={3} rowSpacing={2} columnSpacing={{ md: 2, xs: 0 }}>
                  <Grid item md={6} xs={12}>
                    <Block label={t('dialogs.addNewBook.title')}>
                      <RHFTextField name={`title.${currentTabValue}`} />
                    </Block>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Block label={t('dialogs.addNewBook.bookName')}>
                      <RHFTextField name={`bookName.${currentTabValue}`} />
                    </Block>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Block label={t('dialogs.addNewBook.bookAuthor')}>
                      <RHFTextField name={`bookAuthor.${currentTabValue}`} />
                    </Block>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Block label={t('dialogs.addNewBook.bookLanguage')}>
                      <RHFTextField name={`bookLanguage.${currentTabValue}`} />
                    </Block>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Block label={t('dialogs.addNewBook.fromSchool')}>
                      <RHFTextField name={`fromSchool.${currentTabValue}`} />
                    </Block>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Block label={t('dialogs.addNewBook.nazemName')}>
                      <RHFTextField name={`nazemName.${currentTabValue}`} />
                    </Block>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <AppButton
                  label={isEditing ? t('buttons.edit') : t('buttons.add')}
                  isLoading={false}
                  type="submit"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
      </Container>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewBook;
