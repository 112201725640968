// @mui
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// components
import moment from 'moment';
import Image from 'src/components/image/image';
import { useSettingsContext } from 'src/components/settings';
import { UsersQuery } from 'src/graphql';

// ----------------------------------------------------------------------

type Props = {
  row: UsersQuery['users'][0];
};

export default function UserRow({ row }: Props) {
  const { themeMode } = useSettingsContext();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <Image src={row.avatar} width={25} borderRadius='50%' />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {row.firstName! + ' ' + row.lastName}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.email}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {moment(row.createdAt).format('YYYY-MM-DD')}
        </TableCell>
      </TableRow>
    </>
  );
}
