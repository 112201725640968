import { yupResolver } from '@hookform/resolvers/yup';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  alpha,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import {
  Order_By,
  useAddMatnMutation,
  useAllReferenceBooksQuery,
  useAttachMatnMutation,
  useUpdateMatnMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { AR_CONTENT_LANG, EN_CONTENT_LANG, useLocales } from 'src/locales';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddMatnMutationVariables, NewMatnDialogPropsType } from 'src/types/mutoon';
import { getLocalizedValue } from 'src/utils/get-localized-value';
import { findParentIdString } from 'src/utils/mutoon-categories/find-last-id';

type OptionType = {
  value: string;
  label: string;
};
const AddNewMatnDialog = ({ open, onClose, matn }: NewMatnDialogPropsType) => {
  if (!open) return <></>;
  // #region States
  const addBookDialog = useBoolean();
  const { t } = useLocales();
  const { categoryId, '*': splat } = useParams();

  // Check if the matn is coming from mutoons table or from mutoons_categories table
  const checkedMatn =
    matn?.__typename === 'mutoons'
      ? matn
      : matn?.__typename === 'mutoon_categories'
      ? matn.mutoon
      : undefined;

  const queryClient = useQueryClient();
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<AddMatnMutationVariables>();
  const requiredLangs = [AR_CONTENT_LANG(t), EN_CONTENT_LANG(t)];
  const [currentTabValue, setCurrentTabValue] = useState(requiredLangs[0].value);
  const { matnSchema } = useSchemas();
  const isEditing = !!matn;
  const categoryIdIdentifier = categoryId || findParentIdString(splat)!;
  // #endregion States

  let defaultValues: AddMatnMutationVariables = {
    description: checkedMatn?.description || {},
    name: checkedMatn?.name || {},
    numberOfHadith: checkedMatn?.numberOfHadith || 1,
    referenceBookBookId: checkedMatn?.referenceBookBookId || undefined!,
    matnId: checkedMatn?.id || '',
  };

  // #region form
  for (let i = 0; i < requiredLangs.length && !matn; i++) {
    defaultValues.description[requiredLangs[i].value] = '';
    defaultValues.name[requiredLangs[i].value] = '';
  }

  const methods = useForm<AddMatnMutationVariables>({
    defaultValues,
    resolver: yupResolver(matnSchema(requiredLangs)),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddMatnMutationVariables) => {
      delete data.matnId;
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = Object.values(errors)
    .map((errors) => Object.keys(errors))
    .flat()
    .filter((value, index, array) => array.indexOf(value) !== index);
  // #endregion form

  // #region Services
  const { data: books, isLoading } = useAllReferenceBooksQuery({
    order_by: { bookName: Order_By.Asc },
  });

  const { mutate: addMatn, isLoading: isAdding } = useAddMatnMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] });
      confirm.onFalse();
      onClose();
      categoryIdIdentifier &&
        attachMatn({
          categoryId: categoryIdIdentifier,
          mutoonId: data.insert_mutoons_one?.id,
        });
    },
  });

  const { mutate: editMatn, isLoading: isUpdating } = useUpdateMatnMutation({
    onSuccess: () => {
      onClose();
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] });
    },
  });

  const { mutate: attachMatn, isLoading: isAttaching } = useAttachMatnMutation({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] });
    },
  });

  const mutate = isEditing
    ? () =>
        editMatn({
          ...dataToSend,
          matnId: checkedMatn?.id,
        })
    : () =>
        addMatn({
          ...dataToSend,
          // categoryId: categoryIdIdentifier,
        });
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers
  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description[currentTabValue] || ''
    );
    methods.setValue(`name.${currentTabValue}`, methods.watch().name[currentTabValue] || '');
  }, [methods, currentTabValue]);
  // #endregion useEffect
  return (
    <>
      <Dialog
        open={open && !confirm.value && !addBookDialog.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {t('dialogs.addNewMatn.header')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <AppLoadingAndErrorWrapper
              isLoading={isLoading}
              errorMessage={null}
              sx={{ height: 'auto' }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Stack py={1} spacing={2}>
                      <Stack spacing={1}>
                        <Block label={t('dialogs.addNewMatn.numberOfHadith')}>
                          <RHFTextField name="numberOfHadith" type="number" />
                        </Block>
                        <Block label={t('dialogs.addNewMatn.PDF')}>
                          <RHFSelect name="referenceBookBookId">
                            {books?.reference_books?.map((book) => (
                              <MenuItem key={book.bookId} value={book.bookId}>
                                {getLocalizedValue(book.bookName)}
                              </MenuItem>
                            ))}
                          </RHFSelect>
                        </Block>
                      </Stack>
                      <Tabs
                        value={currentTabValue}
                        onChange={handleChangeTab}
                        sx={{
                          px: 3,
                          boxShadow: (theme) =>
                            `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                        }}
                      >
                        {requiredLangs!?.map((tab) => (
                          <Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.label}
                            sx={{
                              color: langsWithErrors.includes(tab.value)
                                ? 'red !important'
                                : 'text.primary',
                            }}
                          />
                        ))}
                      </Tabs>
                    </Stack>
                    <Stack spacing={1}>
                      <Block label={t('dialogs.addNewMatn.name')}>
                        <RHFTextField name={`name.${currentTabValue}`} />
                      </Block>
                      <Block label={t('dialogs.addNewMatn.description')}>
                        <RHFTextField name={`description.${currentTabValue}`} />
                      </Block>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppButton
                      label={isEditing ? t('buttons.edit') : t('buttons.add')}
                      isLoading={false}
                      type="submit"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppButton
                      onClick={onClose}
                      label={t('buttons.cancel')}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Container>
        </DialogContent>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewMatnDialog;
