import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Container, Grid } from '@mui/material';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import useAddNewFAQ from './use-add-new-faq';
import { Helmet } from 'react-helmet-async';

const AddNewFAQ = () => {
  const {
    isLoadingFAQ,
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    isEditing,
    confirm,
    isAdding,
    isUpdating,
    mutate,
    handleChangeTab,
    langsWithErrors,
  } = useAddNewFAQ();
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>{t('titles.addFaq')}</title>
      </Helmet>

      <Container>
        <AppLoadingAndErrorWrapper
          isLoading={isLoadingFAQ}
          errorMessage={null}
          sx={{ height: 'auto' }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item container xs={6}>
                <Grid item xs={12} display="flex" gap={4} flexDirection="column">
                  <TranslationTabs
                    currentTabValue={currentTabValue}
                    handleChangeTab={handleChangeTab}
                    errors={langsWithErrors}
                  />
                  <Block label={t('tables.faqs.headLabels.question')}>
                    <RHFTextField name={`question.${currentTabValue}`} />
                  </Block>
                  <Block label={t('tables.faqs.headLabels.answer')} required={false}>
                    <RHFTextField name={`answer.${currentTabValue}`} multiline minRows={12} />
                  </Block>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              ></Grid>
              <Grid item xs={12} md={6}>
                <AppButton
                  label={isEditing ? t('buttons.edit') : t('buttons.add')}
                  isLoading={false}
                  type="submit"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
      </Container>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewFAQ;
