import { useContext, createContext } from 'react';
import { AddNewHearingCouncilContextType } from './type';

// ----------------------------------------------------------------------

export const AddNewHearingCouncilContext = createContext({} as AddNewHearingCouncilContextType);

export const useAddNewHearingCouncilContext = () => {
  const context = useContext(AddNewHearingCouncilContext);

  if (!context) throw new Error('useAddNewHearingCouncil must be use inside SettingsProvider');

  return context;
};
