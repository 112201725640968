import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { UseFormReset, UseFormReturn } from 'react-hook-form';
import { ReturnType } from 'src/hooks/use-boolean';
import { DecodedValueMap, SetQuery } from 'use-query-params';
import FormProvider from '../hook-form/form-provider';
import Iconify from '../iconify/iconify';
import Scrollbar from '../scrollbar';
import AppTableToolbar from './AppTableToolbar';
import AppTableRow, { Paths } from './app-table-row';
import TableHeadCustom, { TableHeadPropsType } from './table-head-custom';
import TablePaginationCustom from './table-pagination-custom';
import TableSelectedAction from './table-selected-action';
import TableSkeleton from './table-skeleton';
import { IAppTableToolbarProps, TableProps } from './types';
import TableTabs from './table-tabs';

export type AppTablePropsType<TRow> = {
  title?: string;
  query?: DecodedValueMap<any>;
  methods?: UseFormReturn<any>;
  handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setQuery?: SetQuery<any>;
  headLabels: TableHeadPropsType<any>['headLabel'];
  dataCount: number;
  buttons?: IAppTableToolbarProps<any>['buttons'];
  isLoading?: boolean;
  data: Array<TRow>;
  columns: ({
    name: Paths<TRow>;
    type?: 'text' | 'date';
    isChip?: boolean;
    PreviewComponent?: (data: TRow, index: number) => JSX.Element | string;
    cellSx?: SxProps;
    collapsible?: boolean; // To render collapsible rows
  } | null)[]; // Ensure columns contain keys of TRow
  filters?: IAppTableToolbarProps<any>['filters'];
  table: TableProps;
  openFilters?: ReturnType;
  reset?: UseFormReset<any>;
  // Select action props
  select?: {
    handleSelectRow: (row: TRow, table: TableProps, index: number) => void;
    handleSelectAllRows: (checked: boolean, table: TableProps, rows: TRow[]) => void;
    idPath: Paths<TRow>;
    rowCount: number;
    numSelected: number;
    selectedRowsActions?: {
      icon: string;
      color?: string;
      tooltip?: string;
      handler: () => void;
      isLoading?: boolean;
    }[];
  };
  sx?: SxProps;
  changeTabsHandler?: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => void;
  tabs?: { tabs: { label: string; value: string | null }[]; currentTabValue: string | null };
  areFiltersApplied?: boolean;
  removePagination?: boolean;
};

const AppTable = <TRow,>({
  handleSearch,
  methods,
  query,
  setQuery,
  title,
  headLabels,
  dataCount,
  buttons,
  isLoading,
  data,
  columns,
  filters,
  table,
  openFilters,
  reset,
  select,
  sx,
  changeTabsHandler,
  tabs,
  removePagination = false,
}: AppTablePropsType<TRow>) => {
  return (
    <Card sx={sx!}>
      {title && <CardHeader title={title} />}
      {title && <Divider sx={{ mt: 3 }} />}
      <FormProvider methods={methods!}>
        <AppTableToolbar
          handleSearch={handleSearch}
          search={query?.search!}
          tableName=""
          buttons={buttons}
          filters={filters}
          openFilters={openFilters}
          setQuery={setQuery}
          reset={reset}
        />
        <TableContainer>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={+select?.rowCount!}
            onSelectAllRows={(checked) => select?.handleSelectAllRows(false, table, [])}
            action={select!?.selectedRowsActions?.map((action) => (
              <Tooltip title={action!?.tooltip}>
                <IconButton onClick={action.handler} disabled={action.isLoading}>
                  <Iconify
                    icon={action.isLoading ? 'line-md:loading-twotone-loop' : action.icon}
                    width="27"
                    height="27"
                    color={action!?.color || '#FFAB8B'}
                  />
                </IconButton>
              </Tooltip>
            ))}
          />
          <Scrollbar>
            {tabs && changeTabsHandler && (
              <TableTabs handleChangeTab={changeTabsHandler} tabs={tabs} />
            )}
            <Table>
              <TableHeadCustom<TRow>
                onSelectAllRows={select?.handleSelectAllRows}
                data={data}
                table={table}
                setQuery={setQuery}
                headLabel={headLabels}
                methods={methods}
                numSelected={select?.numSelected}
                rowCount={select?.rowCount}
              />
              <TableBody>
                {isLoading
                  ? [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  : data?.map((row, index) => {
                      return (
                        <AppTableRow<typeof row>
                          key={index}
                          index={index}
                          data={row}
                          columns={columns}
                          selectRow={{
                            handleSelectRow: select?.handleSelectRow!,
                            table: table,
                            id: (row as any)[select?.idPath],
                          }}
                        />
                      );
                    })}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        {data && !removePagination && (
          <TablePaginationCustom
            count={dataCount}
            page={+table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        )}
      </FormProvider>
    </Card>
  );
};

export default AppTable;
