import { JitsiMeeting } from '@jitsi/react-sdk';
import { Card, Container, IconButton, Stack, Tooltip } from '@mui/material';
import AppButton from 'src/components/common/AppButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify/iconify';
import ConnectedUsers from './connected-users/connected-users';
import useHearingCouncilJoin from './use-hearing-council-join';

const HearingCouncilJoin = () => {
  const {
    domain,
    hearingCouncilId,
    jwt,
    returnToPath,
    router,
    handleApiReady,
    toggleShare,
    t,
    handleEndCallForAll,
    leaveMeeting,
    connectedUsers,
    confirmEndCall,
  } = useHearingCouncilJoin();

  return (
    <Container maxWidth="xl" sx={{ height: '100vh' }}>
      <Stack sx={{ width: '100%', p: 2 }} spacing={2}>
        <Stack spacing={1} alignItems="center" justifyContent="space-between" direction="row">
          <Card
            sx={{ height: '100vh', width: '100%', position: 'relative' }}
            className="jitsi-card"
          >
            <JitsiMeeting
              roomName={hearingCouncilId!}
              domain={domain}
              jwt={jwt!}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = '100%';
                iframeRef.classList.add('jitsi-iframe');
              }}
              configOverwrite={{
                toolbarButtons: ['camera', 'fullscreen', 'microphone', 'shareaudio', 'desktop'],
              }}
              onReadyToClose={() => {
                router(returnToPath);
              }}
              onApiReady={handleApiReady}
              interfaceConfigOverwrite={{}}
            />
          </Card>
          <Stack
            sx={{
              p: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            spacing={2}
          >
            <Tooltip title={t('hearingCouncils.joinPage.startSharing')}>
              <IconButton onClick={toggleShare.onToggle}>
                <Iconify icon="solar:stream-bold" width={30} height={30} color="success.main" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('hearingCouncils.joinPage.endCall')}>
              <IconButton onClick={confirmEndCall.onTrue}>
                <Iconify
                  icon="emojione-monotone:end-arrow"
                  width={30}
                  height={30}
                  color="error.main"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('hearingCouncils.joinPage.leaveMeeting')}>
              <IconButton onClick={leaveMeeting}>
                <Iconify
                  icon="material-symbols:call-end-sharp"
                  width={30}
                  height={30}
                  color="error.main"
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <ConnectedUsers connectedUsers={connectedUsers!} />
      </Stack>

      <ConfirmDialog
        open={confirmEndCall.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirmEndCall.onFalse();
          }
        }}
        content={t('hearingCouncils.joinPage.confirmEnd')}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirmEndCall.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              color="error"
              onClick={() => handleEndCallForAll()}
            />
          </>
        }
      />
    </Container>
  );
};

export default HearingCouncilJoin;
