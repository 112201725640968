// @mui
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
// components
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import EditDiffWordDialog from 'src/sections/dialogs/edit/EditDiffWordDialog';
import { MatnSectionDiffWordsDtoType } from 'src/types/matn-section-diff-words';
import { getLocalizedValue } from 'src/utils/get-localized-value';

// ----------------------------------------------------------------------

type Props = {
  row: MatnSectionDiffWordsDtoType;
  deleteHandler: (categoryId: string) => void;
};

export default function MatnSectionDiffWordsRow({ row, deleteHandler }: Props) {
  const { t, allLangs } = useLocales();
  const editWord = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {getLocalizedValue(row.word)}
        </TableCell>
        {allLangs.map((lang) => (
          <TableCell key={lang.value} sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
            {row.translatedText!?.[lang.value]}
          </TableCell>
        ))}
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
            <IconButton onClick={editWord.onTrue}>
              <Iconify icon="fluent-mdl2:page-edit" width="27" height="27" color="#FFAB8B" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
            <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.translationId)}>
              <Iconify
                icon="material-symbols:delete-outline"
                width="27"
                height="27"
                color="#DDB6FF"
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <EditDiffWordDialog diffWord={row} open={editWord.value} onClose={editWord.onFalse} />
    </>
  );
}
