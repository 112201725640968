import { Button, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useState } from 'react';
import { useLocales } from 'src/locales';
import AppButton from './AppButton';

type Step = {
  label: string;
  completed?: boolean;
  component: JSX.Element;
};

const AppStepper = ({
  steps,
  hasFormBeenSubmitted,
  mainButtonLabel,
  isEditing = false,
}: {
  steps: Step[];
  hasFormBeenSubmitted: boolean;
  mainButtonLabel: string;
  isEditing?: boolean;
}) => {
  const { t } = useLocales();
  const [activeStep, setActiveStep] = useState<(typeof steps)[number]>(steps[0]);
  const activeStepIndex = steps.findIndex((step) => step.label === activeStep.label);
  const completedSteps = steps.map((step) => step.completed);

  const handleStep = (step: (typeof steps)[number]) => () => {
    setActiveStep(step);
  };

  return (
    <Stack spacing={3}>
      <Stepper nonLinear activeStep={activeStepIndex}>
        {steps.map((step, index) => (
          <Step key={step.label} completed={hasFormBeenSubmitted && completedSteps[index]}>
            <Button
              color={
                index === activeStepIndex ? (!step.completed ? 'error' : 'primary') : 'inherit'
              }
              variant={index === activeStepIndex && !step.completed ? 'outlined' : 'text'}
              onClick={handleStep(step)}
            >
              <StepLabel error={!step.completed} color={!step.completed ? 'error' : 'gray'}>
                {step.label}
              </StepLabel>
            </Button>
          </Step>
        ))}
      </Stepper>

      {activeStep.component}

      <Stack direction="row" spacing={1}>
        <AppButton
          fullWidth
          variant="outlined"
          color="secondary"
          label={t('buttons.prev')}
          disabled={activeStepIndex === 0}
          onClick={handleStep(steps[activeStepIndex - 1])}
        />
        <AppButton
          fullWidth
          label={mainButtonLabel}
          disabled={!isEditing && activeStepIndex !== steps.length - 1}
          type="submit"
        />
        <AppButton
          fullWidth
          variant="outlined"
          color="secondary"
          label={t('buttons.next')}
          disabled={activeStepIndex === steps.length - 1}
          onClick={handleStep(steps[activeStepIndex + 1])}
        />
      </Stack>
    </Stack>
  );
};

export default AppStepper;
