import {
  Card,
  Chip,
  Divider,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import AppButton from 'src/components/common/AppButton';
import TimeCounter from 'src/components/common/CounterTimer';
import CustomPopover from 'src/components/custom-popover';
import Iconify from 'src/components/iconify/iconify';
import TextMaxLine from 'src/components/text-max-line';
import DeleteDialog from 'src/sections/dialogs/delete/DeleteDialog';
import HearingCouncilQrDialog from 'src/sections/dialogs/view/hearing-council-qr-dialog';
import { HearingCouncilDto } from 'src/types/hearing-council';
import { getLocalizedValue } from 'src/utils/get-localized-value';
import useHearingCouncilsCard from './use-hearing-councils-card';
import { UseMutateFunction } from '@tanstack/react-query';
import { DeleteHearingCouncilMutation, Exact } from 'src/graphql';

const HearingCouncilCard = ({
  hearingCouncil,
  mutateDelete,
  isDeleting,
}: {
  hearingCouncil: HearingCouncilDto;
  isDeleting: boolean;
  mutateDelete: UseMutateFunction<
    DeleteHearingCouncilMutation,
    unknown,
    Exact<{
      hearingCouncilId: string;
    }>,
    unknown
  >;
}) => {
  const {
    buttonLabel,
    deleteDialog,
    isButtonEnabled,
    isJoining,
    isSubscribedToHearingCouncil,
    isSubscribing,
    qrDialog,
    subscribe,
    t,
    adminId,
    join,
    chipColor,
    CONTEXT_ITEMS,
    popover,
  } = useHearingCouncilsCard({ hearingCouncil });

  if (isDeleting)
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: 118,
          borderRadius: 2,
        }}
      />
    );

  return (
    <Card sx={{ p: 3, backgroundColor: 'background.paper', width: '100%', height: '100%' }}>
      <Stack spacing={1}>
        {/* Title */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack alignItems="center" direction="row" spacing={1}>
            <TextMaxLine line={1}>
              <Typography variant="subtitle1">
                {getLocalizedValue(hearingCouncil.sessionName)}
              </Typography>
            </TextMaxLine>
            <Chip
              color={chipColor(hearingCouncil.status as 'pending' | 'live' | 'expired')}
              label={t(`sessions.status.${hearingCouncil.status as 'Pending'}`)}
              variant="soft"
              size="small"
            />
          </Stack>
          <IconButton
            onClick={popover.onOpen}
            sx={{
              width: 40,
              height: 40,
              ...(popover.open && {
                bgcolor: 'action.selected',
              }),
            }}
          >
            <Iconify icon="qlementine-icons:menu-dots-16" sx={{ borderRadius: 0.65, width: 28 }} />
          </IconButton>
        </Stack>

        <Divider />

        {/* Description */}
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">{getLocalizedValue(hearingCouncil.description)}</Typography>
        </Stack>

        {/* Session date */}
        <TimeCounter
          eventTime={hearingCouncil.startDate}
          endTime={hearingCouncil.expiryDate}
          repeatedOn={hearingCouncil.days!}
        />

        <Stack direction="row" alignItems="center" spacing={1}>
          {/* Join button */}
          {isSubscribedToHearingCouncil && buttonLabel && (
            <AppButton
              label={buttonLabel}
              size="small"
              type="button"
              onClick={() => join({ hearingCouncilId: hearingCouncil.hearingCouncilId })}
              isLoading={isJoining}
              disabled={!isButtonEnabled}
              fullWidth
            />
          )}
          {/* Subscribe button */}
          {!isSubscribedToHearingCouncil &&
            !(hearingCouncil.status?.toLowerCase() === 'expired') && (
              <AppButton
                label={t('buttons.subscribe')}
                size="small"
                type="button"
                onClick={() =>
                  subscribe({ hearingCouncilId: hearingCouncil.hearingCouncilId, adminId })
                }
                isLoading={isSubscribing}
                fullWidth
              />
            )}
        </Stack>
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose}>
        {CONTEXT_ITEMS.map((iconBtn) => (
          <MenuItem key={iconBtn.icon} onClick={iconBtn.onClick}>
            <Stack alignItems="center" direction="row">
              <Iconify icon={iconBtn.icon} color={iconBtn.color} />
              <Typography color={iconBtn.color} variant="caption">
                {iconBtn.label}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </CustomPopover>

      <DeleteDialog
        idWord="hearingCouncilId"
        id={hearingCouncil.hearingCouncilId}
        isDeleting={isDeleting}
        open={deleteDialog.value}
        onClose={deleteDialog.onFalse}
        mutate={mutateDelete}
      />
      <HearingCouncilQrDialog
        open={qrDialog.value}
        onClose={qrDialog.onFalse}
        hearingCouncilId={hearingCouncil.hearingCouncilId}
      />
    </Card>
  );
};

export default HearingCouncilCard;
