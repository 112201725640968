import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid } from '@mui/material';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';
import RHFDatePicker from 'src/components/hook-form/rhf-date-picker';
import { getLocalizedValue } from 'src/utils/get-localized-value';
import useAddNewSession from './use-add-new-session';

const AddNewSession = () => {
  const {
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    handleChangeTab,
    allLangs,
    langsWithErrors,
    t,
    hasGotMtns,
    mutoonOptions,
    isEditing,
    createSession,
    isAdding,
    dataToSend,
    confirm,
  } = useAddNewSession();

  const mutoonOpts =
    mutoonOptions?.mutoon_categories.map((mtn) => ({
      label: getLocalizedValue(mtn.mutoon.name),
      value: mtn.mutoonId!,
      category: getLocalizedValue(mtn.category.name),
    })) || [];

  return (
    <>
      <Container>
        <Helmet>
          <title>{t('titles.addSession')}</title>
        </Helmet>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <TranslationTabs
                currentTabValue={currentTabValue}
                handleChangeTab={handleChangeTab}
                errors={langsWithErrors}
              />
              <Grid container py={3} rowSpacing={2} columnSpacing={{ md: 2, xs: 0 }}>
                <Grid item md={6} xs={12}>
                  <Block label={t('dialogs.addNewSession.title')}>
                    <RHFTextField name={`title.${currentTabValue}`} />
                  </Block>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Block label={t('dialogs.addNewSession.description')}>
                    <RHFTextField name={`description.${currentTabValue}`} />
                  </Block>
                </Grid>
              </Grid>
            </Grid>

            {/* #region Jitsi preferences */}
            {/* TODO: reenable jitsi preferences */}
            {/* <Grid item xs={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewSession.accordions.jitsiPreferences')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableConversation')}
                        >
                          <RHFSwitch name="enable_conversation" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableRaiseHand')}
                        >
                          <RHFSwitch name="enable_raise_hand" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableVideo')}
                        >
                          <RHFSwitch name="enable_video" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableRecording')}
                        >
                          <RHFSwitch name="enable_recording" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableUploadFiles')}
                        >
                          <RHFSwitch name="enable_upload_files" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid> */}
            {/* #endregion Jitsi preferences */}

            {/* #region date and time */}
            <Grid item xs={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewSession.accordions.dateAndTime')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Block label={t('dialogs.addNewSession.startDate')}>
                        <RHFDatePicker minDate={new Date()} name="startDate" dateAndTime />
                      </Block>
                    </Grid>
                    <Grid item xs={6}>
                      <Block label={t('dialogs.addNewSession.expiryDate')}>
                        <RHFDatePicker
                          minDate={moment(methods.watch().startDate).toDate()}
                          name="expiryDate"
                          dateAndTime
                        />
                      </Block>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Block label={t('dialogs.addNewSession.days')}>
                        <RHFMultiSelect options={DAYS(t)} name="days" checkbox chip />
                      </Block>
                    </Grid>
                    <Grid item xs={6}>
                      <Block label={t('dialogs.addNewSession.duration')}>
                        <RHFTextField name="duration" type="number" />
                      </Block>
                    </Grid>
                    <Grid item xs={6}>
                      <Block label={t('dialogs.addNewSession.frequency')}>
                        <RHFSelect name="frequency">
                          {FREQUENCY(t).map((frq) => (
                            <MenuItem value={frq.value}>{frq.label}</MenuItem>
                          ))}
                        </RHFSelect>
                      </Block>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* #endregion date and time */}

            {/* #region Matn details */}
            <Grid item xs={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewSession.accordions.matnDetails')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Block label={t('dialogs.addNewHearingCouncil.matn')}>
                        <RHFAutocomplete
                          name="mutoonMatnName"
                          valueName="mutoonMatnId"
                          disabled={!hasGotMtns}
                          options={mutoonOpts}
                          groupBy={(option) => option.category}
                        />
                      </Block>
                    </Grid>

                    {/* <Grid item md={6} xs={12}>
                      <Block label={t('dialogs.addNewSession.prerequisites')}>
                        <RHFMultiSelect
                          name="prerequisiteIds"
                          options={prerequisitesOptions || []}
                          disabled={!hasGotMtns}
                          chip
                          checkbox
                        />
                      </Block>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* TODO: uncomment when done */}
            {/* <Grid item xs={6}>
              <Block label={t('dialogs.addNewHearingCouncil.price')}>
                <RHFTextField name="price" type="number" />
              </Block>
            </Grid>
            <Grid item xs={6}>
              <Block label={t('dialogs.addNewHearingCouncil.appointmentLimit')}>
                <RHFTextField name="appointmentLimit" type="number" />
              </Block>
            </Grid> */}

            {/* #endregion matn details */}
            <Grid item xs={12} md={6}>
              <AppButton
                label={isEditing ? t('buttons.edit') : t('buttons.add')}
                isLoading={false}
                type="submit"
                fullWidth
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isAdding}
              onClick={() => createSession(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewSession;
