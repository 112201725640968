export const sessionButtonLabel = (status: 'Live' | 'Expired' | 'Pending' | 'Paused' | '') => {
  switch (status) {
    case 'Live':
      return 'sessions.card.join' as 'sessions.card.join';
    case 'Pending':
      return 'sessions.card.startNow' as 'sessions.card.startNow';
    // In expired and paused, no button will be there
    case 'Expired':
      return '' as 'sessions.card.startNow';
    case 'Paused':
      return '' as 'sessions.card.startNow';
    default:
      return '' as 'sessions.card.startNow';
  }
};
