import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { API_URLS } from 'src/api/apiUrls';
import useApiServices from 'src/api/useApiServices';
import {
  useAddBadgeMutation,
  useCategoriesQuery,
  useEditBadgeMutation,
  useHearingCouncilsQuery,
  useMutoonsQuery,
  useOneBadgeQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddCustomBadgeVariablesType, BadgesDtoType } from 'src/types/badges';
import { getLocalizedValue } from 'src/utils/get-localized-value';

const useAddNewCustomBadge = () => {
  // #region States
  const confirm = useBoolean();
  const { AddCustomBadgeSchema } = useSchemas();
  const { badgeId: badgeIdParam } = useParams();
  const queryClient = useQueryClient();
  const { t, allLangs, currentLang } = useLocales();
  const router = useNavigate();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const [file, setFile] = useState<File | string | null>(null);
  const [badgeId, setBadgeId] = useState<string | undefined>(badgeIdParam);
  const { state }: { state: { badge: BadgesDtoType | undefined } | undefined } = useLocation();
  const isEditing = !!badgeIdParam;
  const { usePostApi } = useApiServices();
  const [dataToSend, setDataToSend] = useState<AddCustomBadgeVariablesType>();
  // #endregion States

  const BADGE_TYPE_DEFAULT = state?.badge?.categoryId
    ? 'category'
    : state?.badge?.mutoonId
    ? 'matn'
    : 'hearingCouncil';

  let defaultValues: AddCustomBadgeVariablesType = {
    programName: state?.badge?.programName || {},
    badgeImageURL: state?.badge?.badgeImageURL || '',
    badgeType: BADGE_TYPE_DEFAULT || 'matn',
    categoryId: state?.badge?.categoryId || null,
    hearingCouncilId: state?.badge?.hearingCouncilId || null,
    mutoonId: state?.badge?.mutoonId || null,
    categoryName: state?.badge?.category?.name!?.[currentLang.value] || '',
    hearingCouncilName: state?.badge?.hearing_council?.sessionName!?.[currentLang.value] || '',
    mutoonName: state?.badge?.mutoon?.name!?.[currentLang.value] || '',
  };

  // #region form
  for (let i = 0; i < allLangs.length && !state?.badge; i++) {
    defaultValues.programName[allLangs[i].value] = '';
  }

  const methods = useForm<AddCustomBadgeVariablesType>({
    defaultValues,
    resolver: yupResolver<AddCustomBadgeVariablesType>(AddCustomBadgeSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddCustomBadgeVariablesType) => {
      const newData = { ...data };
      if ('categoryName' in newData) delete newData.categoryName;
      if ('hearingCouncilName' in newData) delete newData.hearingCouncilName;
      if ('mutoonName' in newData) delete newData.mutoonName;
      delete (newData as any).badgeType;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // #endregion form

  // #region Services
  // Queries
  const { isFetching: isLoadingBadge } = useOneBadgeQuery(
    {
      id: badgeIdParam!,
    },
    {
      enabled: !state?.badge && !!badgeIdParam,
      onSuccess: (data) => {
        {
          methods.setValue('programName', data.badges_by_pk!?.programName);
          methods.setValue('badgeImageURL', data.badges_by_pk?.badgeImageURL!);
          methods.setValue('categoryId', data.badges_by_pk?.categoryId!);
          methods.setValue('hearingCouncilId', data.badges_by_pk?.hearingCouncilId!);
          methods.setValue('mutoonId', data.badges_by_pk?.mutoonId!);
          methods.setValue(
            'categoryName',
            data.badges_by_pk?.category?.name!?.[currentLang.value]!
          );
          methods.setValue(
            'hearingCouncilName',
            data.badges_by_pk?.hearing_council?.sessionName!?.[currentLang.value]!
          );
          methods.setValue('mutoonName', data.badges_by_pk?.mutoon?.name!?.[currentLang.value]!);
        }
      },
    }
  );

  const { data: hearingCouncils } = useHearingCouncilsQuery(
    {
      limit: null,
    },
    {
      enabled: methods.watch().badgeType === 'hearingCouncil',
    }
  );

  const { data: categories } = useCategoriesQuery(
    {
      limit: null,
    },
    {
      enabled: methods.watch().badgeType === 'category',
    }
  );

  const { data: mutoon } = useMutoonsQuery(
    {
      limit: null,
      levelId: {},
    },
    {
      enabled: methods.watch().badgeType === 'matn',
    }
  );

  // Mutations
  const {
    mutate: addBadge,
    isLoading: isAdding,
    isSuccess: hasAddedBadge,
  } = useAddBadgeMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      setBadgeId(data.insert_badges_one?.badgeId);
    },
  });

  const { mutate: editBadge, isLoading: isUpdating } = useEditBadgeMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['FAQs'] });
      router(paths.dashboard.helpCenter.faqs);
    },
  });

  const {
    mutate: uploadBadgeImg,
    isLoading: isUploadingBadgeImg,
    data,
  } = usePostApi({
    url: API_URLS.UPLOAD_BADGE_IMAGE(badgeId ?? ''),
    withFormData: true,
    onSuccess: (data: any) => {
      methods.setValue('badgeImageURL', data?.avatar);
      !badgeIdParam && router(paths.dashboard.contentManagement.badges.customBadges);
    },
  });

  const mutate = isEditing
    ? () =>
        editBadge({
          ...dataToSend,
          id: badgeIdParam,
        })
    : () =>
        addBadge({
          ...dataToSend,
        });
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);

  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
      methods.setValue('badgeImageURL', URL.createObjectURL(newFile));
    }
  }, []);

  const handleDeleteBadgeImg = (badgeId: string) => {
    editBadge({
      id: badgeId,
      ...methods.watch(),
      badgeType: undefined,
      hearingCouncilName: undefined,
      categoryName: undefined,
      mutoonName: undefined,
      badgeImageURL: '',
    } as any);
  };
  // #endregion handlers

  const BADGE_TYPE_SELECT = [
    {
      valueName: 'hearingCouncilId',
      name: 'hearingCouncilName',
      label: t('badges.types.hearingCouncils'),
      type: 'hearingCouncil',
      options: hearingCouncils?.hearing_councils.map((council) => ({
        label: getLocalizedValue(council.sessionName),
        value: council.hearingCouncilId,
      })),
    },
    {
      valueName: 'categoryId',
      name: 'categoryName',
      label: t('badges.types.category'),
      type: 'category',
      options: categories?.categories.map((ctg) => ({
        label: getLocalizedValue(ctg.name),
        value: ctg.categoryId,
      })),
    },
    {
      valueName: 'mutoonId',
      name: 'mutoonName',
      label: t('badges.types.matn'),
      type: 'matn',
      options: mutoon?.mutoons.map((matn) => ({
        label: getLocalizedValue(matn.name),
        value: matn.id,
      })),
    },
  ].filter((type) => (methods.watch().badgeType ? type.type === methods.watch().badgeType : null));

  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `programName.${currentTabValue}`,
      methods.watch().programName!?.[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);

  useEffect(() => {
    if (file && (hasAddedBadge || state?.badge || badgeId)) uploadBadgeImg({ file: file });
  }, [file, hasAddedBadge]);

  useEffect(() => {
    methods.reset({ ...methods.watch(), categoryId: null, mutoonId: null, hearingCouncilId: null });
  }, [methods.watch().badgeType]);
  // #endregion useEffect

  return {
    BADGE_TYPE_SELECT,
    isLoadingBadge,
    methods,
    handleSubmit,
    onSubmit,
    badgeId,
    currentTabValue,
    file,
    setFile,
    mutate,
    badgeIdParam,
    isEditing,
    confirm,
    isAdding,
    isUpdating,
    langsWithErrors,
    // Handlers
    handleChangeTab,
    handleDeleteBadgeImg,
    handleDropSingleFile,
    // Services
    uploadBadgeImg,
    addBadge,
    editBadge,
    isUploadingBadgeImg,
  };
};

export default useAddNewCustomBadge;
