import LoadingButton from '@mui/lab/LoadingButton';
import { IconProps, SxProps } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import React from 'react';

interface IAppButtonProps extends ButtonProps {
  label: string;
  isLoading?: boolean;
  onClick?: VoidFunction;
  linkCompoennt?: any;
  href?: string;
  type?: 'button' | 'submit';
  variant?: 'contained' | 'outlined' | 'text' | 'soft';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  sx?: SxProps;
  buttonIcon?: React.ReactElement<IconProps>;
}

const NewAppButton: React.FC<IAppButtonProps> = (props) => {
  const {
    label,
    isLoading = false,
    onClick,
    linkCompoennt,
    href,
    type = 'button',
    variant = 'contained',
    color = 'primary',
    sx,
    buttonIcon,
    ...other
  } = props;

  return (
    <LoadingButton
      loading={isLoading}
      onClick={onClick}
      LinkComponent={linkCompoennt}
      href={href}
      type={type}
      variant={variant}
      color={color}
      sx={{
        fontWeight: '700px',
        fontSize: { md: '16px', xs: '12px' },
        padding: { md: '16px 28px', xs: '10px' },
        borderRadius: '70px',
        lineHeight: '24px',
        textTransform: 'capitalize',
        ...sx,
      }}
      startIcon={buttonIcon}
      {...other}
    >
      {label}
    </LoadingButton>
  );
};

export default NewAppButton;
