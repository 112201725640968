import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useQueryClient } from '@tanstack/react-query';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
import NewAppButton from 'src/components/common/NewAppButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import AppTableCell from 'src/components/table/AppTableCell';
import { useDeleteRoleMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { IRoleDto } from 'src/types/roles';
import AppPermissions from 'src/constants/app-permissions';
import AppButton from 'src/components/common/AppButton';

type Props = {
  row: IRoleDto;
};

export default function RolesTableRow({ row }: Props) {
  const confirm = useBoolean();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useDeleteRoleMutation({
    onSuccess: () => {
      confirm.onFalse();
      enqueueSnackbar({ variant: 'success', message: 'Completed Successfully' });
      queryClient.refetchQueries(['Roles']);
    },
  });

  return (
    <>
      <TableRow hover>
        <AppTableCell value={row?.name} />
        <AppTableCell value={row?.admins?.map((item: any) => item?.username)?.join(' - ')} />
        <AppTableCell
          value={
            <>
              <AppPermissionGate permissionsName={[AppPermissions['EDIT_ROLE_MANAGEMENT']]}>
                <Tooltip title="Edit" placement="bottom" arrow>
                  <IconButton
                    disabled={row?.name == 'Admin'}
                    LinkComponent={RouterLink}
                    href={`${paths.dashboard.roles.editRole(row?.id)}`}
                  >
                    <Iconify icon="fa6-solid:user-pen" width="22" height="22" color="#51C1CF" />
                  </IconButton>
                </Tooltip>
              </AppPermissionGate>

              <AppPermissionGate permissionsName={[AppPermissions['DELETE_ROLE_MANAGEMENT']]}>
                <Tooltip title="Delete" placement="bottom" arrow>
                  <IconButton onClick={confirm.onTrue} disabled={row?.name == 'Admin'}>
                    <Iconify
                      icon="solar:trash-bin-trash-bold"
                      width="24"
                      height="24"
                      color="#51C1CF"
                    />
                  </IconButton>
                </Tooltip>
              </AppPermissionGate>
            </>
          }
        />
      </TableRow>

      {/* Delete Dialog */}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        content="Do you really want to delete this role?"
        action={
          <AppButton
            fullWidth
            label="Delete"
            color="error"
            isLoading={isLoading}
            onClick={() => mutate({ id: row?.id })}
          />
        }
      />
    </>
  );
}
