import { Stack, Typography, Tooltip } from '@mui/material';
import { AccessTime, CalendarToday, Replay } from '@mui/icons-material';
import moment from 'moment';
import { useLocales } from 'src/locales';

function TimeCounter({
  eventTime,
  endTime,
  repeatedOn,
}: {
  eventTime: string;
  endTime: string;
  repeatedOn?: string[];
}) {
  const { t } = useLocales();
  const momentEvTime = moment(eventTime);
  const momentEvEndTime = moment(endTime);

  const repeatedOnTxt =
    repeatedOn?.length === 7
      ? t('sessions.card.weekly')
      : repeatedOn?.map((day) => t(`days.${day.toLowerCase() as 'sun'}`))?.join(', ');

  const isRepeated = repeatedOn?.length! > 0 && momentEvTime.diff(momentEvEndTime, 'days') !== 0;

  const date =
    moment(momentEvTime).diff(momentEvEndTime, 'days') !== 0
      ? `${momentEvTime.format('DD-MM-YYYY')} - ${momentEvEndTime.format('DD-MM-YYYY')}`
      : momentEvTime.format('DD-MM-YYYY');

  return (
    <Stack spacing={2}>
      {/* Event Date */}
      <Stack direction="row" alignItems="center" spacing={1}>
        <Tooltip title={t('sessions.card.date')}>
          <CalendarToday color="primary" fontSize="small" />
        </Tooltip>
        <Typography variant="caption">{date}</Typography>
      </Stack>

      {/* Event Time */}
      <Stack direction="row" alignItems="center" spacing={1}>
        <Tooltip title={t('sessions.card.time')}>
          <AccessTime color="primary" fontSize="small" />
        </Tooltip>
        <Typography variant="caption">
          {`${momentEvTime.format('HH:mm')} - ${momentEvEndTime.format('HH:mm')}`}
        </Typography>
      </Stack>

      {/* Repeated Event */}
      <Stack direction="row" alignItems="center" spacing={1} style={{ minHeight: 20 }}>
        {isRepeated ? (
          <>
            <Tooltip title={t('sessions.card.repeatedOn')}>
              <Replay color="primary" fontSize="small" />
            </Tooltip>
            <Typography variant="caption">{repeatedOnTxt}</Typography>
          </>
        ) : (
          <Typography variant="caption" style={{ visibility: 'hidden' }}>
            Placeholder
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default TimeCounter;
