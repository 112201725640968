import LoadingButton from '@mui/lab/LoadingButton';
import { IconProps, SxProps } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import React from 'react';
import { Link } from 'react-router-dom';

export interface IAppButtonProps extends ButtonProps {
  label: string;
  isLoading?: boolean;
  onClick?: VoidFunction;
  linkCompoennt?: any;
  href?: string;
  type?: 'button' | 'submit';
  variant?: 'contained' | 'outlined' | 'text' | 'soft';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  sx?: SxProps;
  buttonIcon?: React.ReactElement<IconProps>;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

export const AppButton: React.FC<IAppButtonProps> = (props) => {
  const {
    label,
    isLoading = false,
    onClick,
    linkCompoennt,
    href,
    type = 'button',
    variant = 'contained',
    color = 'primary',
    sx,
    buttonIcon,
    target,
    ...other
  } = props;

  return href ? (
    <Link to={href} target={target}>
      <LoadingButton
        loading={isLoading}
        onClick={onClick}
        LinkComponent={linkCompoennt}
        type={type}
        variant={variant}
        color={color}
        sx={{
          fontWeight: '700px',
          fontSize: { md: '16px', xs: '12px' },
          borderRadius: 1,
          lineHeight: '24px',
          textTransform: 'capitalize',
          ...sx,
        }}
        startIcon={buttonIcon}
        {...other}
      >
        {label}
      </LoadingButton>
    </Link>
  ) : (
    <LoadingButton
      loading={isLoading}
      onClick={onClick}
      LinkComponent={linkCompoennt}
      type={type}
      variant={variant}
      color={color}
      sx={{
        fontWeight: '700px',
        fontSize: { md: '16px', xs: '12px' },
        borderRadius: 1,
        lineHeight: '24px',
        textTransform: 'capitalize',
        ...sx,
      }}
      startIcon={buttonIcon}
      {...other}
    >
      {label}
    </LoadingButton>
  );
};

export default AppButton;
