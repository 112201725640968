import { Card, Chip, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import AppButton from 'src/components/common/AppButton';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
import TimeCounter from 'src/components/common/CounterTimer';
import Iconify from 'src/components/iconify/iconify';
import AppPermissions from 'src/constants/app-permissions';
import DeleteDialog from 'src/sections/dialogs/delete/DeleteDialog';
import { SessionDtoType } from 'src/types/sessions';
import useSessionCard from './use-session-card';
import { getLocalizedValue } from 'src/utils/get-localized-value';

const SessionCard = ({
  session,
  mutateDelete,
  isDeleting,
}: {
  session: SessionDtoType;
  isDeleting: boolean;
  mutateDelete: any;
}) => {
  const {
    deleteDialog,
    buttonLabel,
    isSessionExpired,
    isSubscribedToSession,
    isSubscribing,
    join,
    hasSessionStarted,
    isJoining,
    subscribe,
    t,
    chipColor,
  } = useSessionCard({ session });

  if (isDeleting)
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: 118,
          borderRadius: 2,
        }}
      />
    );

  return (
    <Card sx={{ p: 3, backgroundColor: 'background.paper', width: '100%', height: '100%' }}>
      <Stack spacing={1}>
        {/* Title */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">{getLocalizedValue(session.title)}</Typography>
          <Chip
            variant="soft"
            color={chipColor}
            label={t(`sessions.status.${session.status}` as any)}
          />
        </Stack>

        <Divider />

        {/* Description */}
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant='body2'>{getLocalizedValue(session.description)}</Typography>
          <AppPermissionGate permissionsName={[AppPermissions.SESSIONS_DELETE]}>
            <IconButton onClick={deleteDialog.onTrue}>
              <Iconify icon="material-symbols:delete-outline" color="error.light" />
            </IconButton>
          </AppPermissionGate>
        </Stack>
        {/* Session date */}
        <Typography>
          <TimeCounter eventTime={session.startDate} endTime={session.expiryDate} />
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {isSubscribedToSession && isSessionExpired && buttonLabel ? (
            <AppButton
              fullWidth
              label={buttonLabel as any}
              size="small"
              onClick={() => join({ sessionId: session.id })}
              isLoading={isJoining}
              disabled={!hasSessionStarted}
              type="button"
            />
          ) : (
            <></>
          )}
          {/* Subscribe button */}
          {!isSubscribedToSession && isSessionExpired && (
            <AppButton
              fullWidth
              type="button"
              label={t('buttons.subscribe')}
              size="small"
              onClick={() => subscribe({ sessionId: session.id })}
              isLoading={isSubscribing}
            />
          )}
        </Stack>
      </Stack>
      <DeleteDialog
        id={session.id}
        mutate={mutateDelete}
        idWord="id"
        isDeleting={isDeleting}
        open={deleteDialog.value}
        onClose={deleteDialog.onFalse}
      />
    </Card>
  );
};

export default SessionCard;
