/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

// ----------------------------------------------------------------------

import Router from 'src/routes/sections';

import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import ThemeProvider from 'src/theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
import { AuthProvider } from 'src/auth/context/jwt';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import queryString from 'query-string';
import 'src/locales/i18n';
// editor
import 'react-quill/dist/quill.snow.css';
import { useLocales } from './locales';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  const { t } = useLocales();

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <title>{t('titles.mutoonAdminDashboard')}</title>
      </Helmet>
      <AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <SnackbarProvider>
              <MotionLazy>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <SettingsDrawer />
                  <ProgressBar />
                  <QueryParamProvider
                    adapter={ReactRouter6Adapter}
                    options={{
                      searchStringToObject: queryString.parse,
                      objectToSearchString: queryString.stringify,
                    }}
                  >
                    <Router />
                  </QueryParamProvider>
                </LocalizationProvider>
              </MotionLazy>
            </SnackbarProvider>
          </ThemeProvider>
        </SettingsProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
