import { Grid } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Block } from 'src/components/Block';
import { RHFTextField } from 'src/components/hook-form';
import RHFDatePicker from 'src/components/hook-form/rhf-date-picker';
import { RHFMultiSelect } from 'src/components/hook-form/rhf-select';
import { DAYS } from 'src/constants/days';
import { useAddNewHearingCouncilContext } from '../context/add-new-hearing-council-context';
import { DateToDayName } from 'src/utils/date-to-day-name';

const CouncilScheduling = () => {
  const { methods, t, isCouncilJustOneDay, today } = useAddNewHearingCouncilContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Block label={t('dialogs.addNewHearingCouncil.startDate')}>
          <RHFDatePicker
            name="startDate"
            dateAndTime
            minDate={new Date()}
            // Event here is actually the new value which is date
            onChange={(event: any) =>
              isCouncilJustOneDay(event)() && methods.setValue('days', [DateToDayName(event)])
            }
          />
        </Block>
      </Grid>
      <Grid item xs={6}>
        <Block label={t('dialogs.addNewHearingCouncil.expiryDate')}>
          <RHFDatePicker
            name="expiryDate"
            dateAndTime
            minDate={moment(methods.watch().startDate).toDate()}
            onChange={(event: any) =>
              isCouncilJustOneDay()(event) && methods.setValue('days', [DateToDayName(event)])
            }
          />
        </Block>
      </Grid>
      {/* <Grid item xs={6}>
      <Block label={t('dialogs.addNewSession.duration')}>
        <RHFTextField
          name="duration"
          type="number"
          value={durationValue}
          disabled
        />
      </Block>
    </Grid> */}
      <Grid item xs={6}>
        <Block label={t('dialogs.addNewSession.days')}>
          <RHFMultiSelect
            options={DAYS(t)}
            name="days"
            checkbox
            chip
            disabled={isCouncilJustOneDay()()}
          />
        </Block>
      </Grid>
      <Grid item xs={6}>
        <Block label={t('dialogs.addNewHearingCouncil.appointmentLimit')}>
          <RHFTextField name="appointmentLimit" type="number" />
        </Block>
      </Grid>
    </Grid>
  );
};

export default CouncilScheduling;
