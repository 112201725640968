import React from 'react';
import AddNewHearingCouncil from './add-new-hearing-council';
import AddNewHearingCouncilProvider from './context/add-new-hearing-council-provider';

const AddNewHearingCouncilPage = () => {
  return (
    <AddNewHearingCouncilProvider>
      <AddNewHearingCouncil />
    </AddNewHearingCouncilProvider>
  );
};

export default AddNewHearingCouncilPage;
