import { TableCell, Checkbox } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import { TableProps, useTable } from 'src/components/table';
import { AppTablePropsType } from 'src/components/table/app-table';
import RowActions, { RowActionsPropsType } from 'src/components/table/table-row-actions';
import AppPermissions from 'src/constants/app-permissions';
import {
  Order_By,
  SchoolStudentsQuery,
  useCreateCouncilCertificateMutation,
  useSchoolStudentsQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { default as UseFormWithSearch, default as UseSearch } from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { handleNoCertificatesSelected } from 'src/pages/dashboard/hearing-councils/analysis/table/utils/helper';
import { orderBy } from 'src/utils/order-by';

const useStudents = ({
  userId,
  newTable,
  certificateHearingCouncilId,
}: {
  newTable?: TableProps;
  userId: string;
  certificateHearingCouncilId: string;
}) => {
  // #region States and hooks
  const { t } = useLocales();
  const queryClient = useQueryClient();
  const openFilters = useBoolean();
  const router = useNavigate();
  const confirm = useBoolean();
  const changeStatusDialog = useBoolean();
  const addToPlaylistDialog = useBoolean();
  const isCertificatePreview = useBoolean();
  const [selectedRow, setSelectedRow] = useState<SchoolStudentsQuery['students'][0]>();
  const table = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 0,
  });

  const { hearingCouncilId } = useParams();

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
    status: string | undefined;
    createdAt: {
      operator: '_lt' | '_gt';
      value: string;
    };
  }>({}, 'searchStudents');

  // This is used only in students attendance
  // #endregion

  // #region Services
  const { data: students, isLoading } = useSchoolStudentsQuery(
    {
      offset: newTable?.offset || table.offset,
      limit: newTable?.rowsPerPage || table.rowsPerPage,
      // order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
      search: query.searchStudents ? { _ilike: `%${query.searchStudents}%` } : {},
      userId: userId,
      hearingCouncilId: hearingCouncilId!,
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  const { mutate: createCert, isLoading: isCreatingCert } = useCreateCouncilCertificateMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      isCertificatePreview.value &&
        window.open(
          data.createUserHearingCouncilCertificate[0].userCertificate?.downloadPath!,
          '_blank'
        );
      // Return isCertificatePreview to false
      isCertificatePreview.onFalse();
      queryClient.invalidateQueries({ queryKey: ['HearingCouncilAnalysis'] });
      queryClient.invalidateQueries({ queryKey: ['SchoolStudents'] });
      if (!data.createUserHearingCouncilCertificate.length) {
        enqueueSnackbar({
          message: t('tables.finishedHearingCouncilsAnalysis.createCertResWarning'),
          variant: 'warning',
        });
      }
    },
  });
  // #endregion Services

  // #region Handlers
  const hasUserBeenAttended = (student: SchoolStudentsQuery['students'][0]) => {
    const studentUserHearingCouncil = student.user_hearing_councils.find(
      (user) => user.hearingCouncilId === hearingCouncilId
    );
    return studentUserHearingCouncil;
  };

  const handleExportCert = (
    row: SchoolStudentsQuery['students'][0],
    isPreview: boolean = false
  ) => {
    createCert({
      createCertficateInput: {
        customName: row.firstName + ' ' + row.lastName,
        userHearingCouncilId: row.user_hearing_councils.find(
          (uhc) => uhc.hearingCouncilId === hearingCouncilId!
        )?.userHearingCouncilId,
        isPreview,
        certificateHearingCouncilId,
      },
    });
  };
  // #endregion Handlers

  const generateMenuOption = (
    row: SchoolStudentsQuery['students'][0],
    index: number
  ): RowActionsPropsType['options'] => {
    const userCertificate = row.user_certificates.find(
      (cert) => cert.userHearingCouncil?.hearingCouncil.hearingCouncilId === hearingCouncilId
    );

    const hasCertificate = row.user_certificates.find(
      (cert) => cert.userHearingCouncil?.hearingCouncil.hearingCouncilId === hearingCouncilId
    );

    // 1- Note that the query gets just the matching hearingCouncil by checking for hearingCouncilId
    //    So the desired hearing council can be accessed by row.user_hearing_councils[0] as it is unique

    // 2- To be able to be certified, the user must not have a certificate and the council's status must be expired
    const canBeCertified = !hasCertificate && row.user_hearing_councils[0].status === 'Expired';

    // Check if this row student is the same as selected in order to show loading indicator just for the matching student
    const isPreviewingCertificate = isCreatingCert && row.id === selectedRow?.id;

    return [
      {
        tooltip: t('dialogs.addNewExam.certificate'),
        icon: 'fluent:certificate-16-regular',
        onClick: !certificateHearingCouncilId
          ? () => handleNoCertificatesSelected(t)
          : () => {
              setSelectedRow(row);
              confirm.onTrue();
            },
        permission: canBeCertified,
      },
      {
        tooltip: t('tableLabels.preview'),
        icon: isPreviewingCertificate ? 'line-md:loading-loop' : 'material-symbols:preview',
        onClick: !certificateHearingCouncilId
          ? () => handleNoCertificatesSelected(t)
          : () => {
              // in order to check if this is the row that the certificate is being exported as preview for
              // in order not to show loading indictors for all rows
              setSelectedRow(row);
              // in order to open the certificate in another tab after success exportation
              isCertificatePreview.onTrue();
              handleExportCert(row, true);
            },
        permission: canBeCertified,
      },
      {
        tooltip: t('dialogs.addNewExam.certificate'),
        icon: 'mdi:eye-circle',
        onClick: () => {
          window.open(userCertificate?.downloadPath!, '_target');
        },
        permission: !!userCertificate,
      },
    ].filter((option) => (option!?.permission === undefined ? option : option!?.permission));
  };

  const columns: AppTablePropsType<SchoolStudentsQuery['students'][0]>['columns'] = [
    {
      name: 'firstName',
    },
    {
      name: 'firstNameEn',
    },
    {
      name: 'lastName',
    },
    {
      name: 'lastNameEn',
    },
    {
      name: 'email',
    },
    {
      name: 'createdAt',
      PreviewComponent: (data) => moment(data.createdAt).format('YYYY-MM-DD'),
    },
    {
      name: 'createdAt',
      PreviewComponent: (data, index) => <RowActions options={generateMenuOption(data, index)} />,
    },
  ];

  const filters = [{}];

  return {
    t,
    table: table || newTable,
    isLoading,
    isCreatingCert,
    columns: columns.filter((col) => col),
    selectedRow,
    addToPlaylistDialog,
    changeStatusDialog,
    openFilters,
    filters,
    handleSearch,
    setQuery,
    query,
    methods,
    students,
    handleExportCert: () => handleExportCert(selectedRow!, isCertificatePreview.value),
    confirm,
  };
};

export default useStudents;
