import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { IAppTableToolbarProps, useTable } from 'src/components/table';
import { HEARING_COUNCILS_STATUS } from 'src/constants/hearing-councils-status';
import {
  Order_By,
  User_Hearing_Councils_Select_Column_User_Hearing_Councils_Aggregate_Bool_Exp_Bool_And_Arguments_Columns,
  useAllMutoonsQuery,
  useDeleteHearingCouncilMutation,
  useHearingCouncilsMutoonsQuery,
  useHearingCouncilsQuery,
  useMutoonsQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { HearingCouncilsFormType } from 'src/types/hearing-council';
import { getLocalizedValue } from 'src/utils/get-localized-value';
import { orderBy } from 'src/utils/order-by';
import { useQueryParams } from 'use-query-params';

const useHearingCouncils = ({ userId }: { userId?: string }) => {
  // #region States and hooks
  const { t } = useLocales();
  const queryClient = useQueryClient();
  const pagination = useTable({ defaultRowsPerPage: 12, defaultCurrentPage: 0 });
  const openFilters = useBoolean();
  const openSort = useBoolean();
  const isInUserDetails = userId;
  const [filtersQuery] = useQueryParams({
    ['startDate.operator']: '',
    ['startDate.value']: '',
    ['expiryDate.operator']: '',
    ['expiryDate.value']: '',
    status: '',
    sortBy: '',
    sortDirection: '',
    matnId: '',
    matnName: '',
  });

  const { methods, query, handleSearch, setQuery } = UseSearch<HearingCouncilsFormType>({
    status: filtersQuery.status || undefined,
    startDate: {
      operator: (filtersQuery['startDate.operator'] as '_gt') || '_gt',
      value: filtersQuery['startDate.value']
        ? moment(filtersQuery['startDate.value']).format()
        : '',
    },
    expiryDate: {
      operator: (filtersQuery['expiryDate.operator'] as '_gt') || '_lt',
      value: filtersQuery['expiryDate.value']
        ? moment(filtersQuery['expiryDate.value']).format()
        : '',
    },
    sortBy: filtersQuery['sortBy']!,
    sortDirection: filtersQuery['sortDirection'] as Order_By.Asc,
    matnId: filtersQuery['matnId']!,
    matnName: filtersQuery['matnName']!,
  });
  // #endregion States and hooks

  // #region Handlers
  const handleResetFilters = () => {
    methods.reset({
      ...methods.watch(),
      status: '',
      expiryDate: { operator: undefined, value: undefined },
      startDate: { operator: undefined, value: undefined },
      matnId: '',
      matnName: '',
    });
    setQuery({
      status: undefined,
      'startDate.value': undefined,
      'startDate.operator': undefined,
      'expiryDate.value': undefined,
      'expiryDate.operator': undefined,
      matnId: undefined,
      matnName: undefined,
    });
  };

  const handleResetSort = () => {
    methods.reset({
      ...methods.watch(),
      sortBy: '',
      sortDirection: '' as Order_By.Asc,
    });
    setQuery({
      sortBy: undefined,
      sortDirection: undefined,
    });
  };
  // #endregion Handlers

  // #region Services
  const { data: hearingCouncils, isLoading: isLoadingHearingCouncils } = useHearingCouncilsQuery({
    offset: pagination.offset,
    limit: pagination.rowsPerPage,
    search: query.search!,
    status: methods.watch().status ? { _eq: methods.watch().status } : {},
    startDate:
      methods.watch().startDate.value && methods.watch().startDate.operator
        ? { [methods.watch().startDate.operator]: methods.watch().startDate.value }
        : {},
    expiryDate:
      methods.watch().expiryDate.value && methods.watch().expiryDate.operator
        ? { [methods.watch().expiryDate.operator]: methods.watch().expiryDate.value }
        : {},
    order_by:
      methods.watch().sortBy && methods.watch().sortDirection
        ? orderBy(methods.watch().sortBy, methods.watch().sortDirection)
        : undefined,
    userId: isInUserDetails ? { _eq: userId } : undefined,
    matnId: methods.watch().matnId ? { _eq: methods.watch().matnId } : undefined,
  });

  const { mutate: deleteHearingCouncil, isLoading: isDeleting } = useDeleteHearingCouncilMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['HearingCouncils'] }),
  });

  const { data: mutoon, isLoading: isLoadingMutoons } = useHearingCouncilsMutoonsQuery();
  // #endregion Services

  // Table Filters
  const FILTERS: IAppTableToolbarProps<any>['filters'] = [
    {
      name: 'status',
      type: 'select',
      options: HEARING_COUNCILS_STATUS(t),
      title: t('hearingCouncils.filters.status'),
    },
    {
      name: 'matnName',
      type: 'autocomplete',
      title: t('tables.exams.headLabels.matn'),
      options:
        mutoon?.mutoons.map((mutoon) => ({
          label: getLocalizedValue(mutoon.name),
          value: mutoon.id,
        })) || [],
      valueName: 'matnId',
    },
    {
      type: 'date',
      name: 'startDate.value',
      opertaorFieldName: 'startDate.operator',
      title: t('hearingCouncils.filters.startDate'),
    },
    {
      type: 'date',
      name: 'expiryDate.value',
      opertaorFieldName: 'expiryDate.operator',
      title: t('hearingCouncils.filters.expiryDate'),
    },
  ];

  // Table sort options
  const SORT_OPTIONS: IAppTableToolbarProps<any>['sortByOptions'] = [
    {
      label: t('dialogs.addNewHearingCouncil.sessionName'),
      value: 'sessionName',
    },
    {
      label: t('dialogs.addNewHearingCouncil.description'),
      value: 'description',
    },
    {
      label: t('dialogs.addNewHearingCouncil.sheikhName'),
      value: 'sheikhName',
    },
    {
      label: t('dialogs.addNewHearingCouncil.startDate'),
      value: 'startDate',
    },
    {
      label: t('dialogs.addNewHearingCouncil.expiryDate'),
      value: 'expiryDate',
    },
    {
      label: t('hearingCouncils.filters.status'),
      value: 'status',
    },
  ];

  return {
    isLoadingHearingCouncils,
    hearingCouncils,
    isInUserDetails,
    methods,
    handleSearch,
    setQuery,
    pagination,
    query,
    t,
    openFilters,
    isDeleting,
    deleteHearingCouncil,
    openSort,
    handleResetFilters,
    handleResetSort,
    FILTERS,
    SORT_OPTIONS,
    mutoon,
    isLoadingMutoons,
  };
};

export default useHearingCouncils;
