import { Card, Container, Divider, Grid, Skeleton, Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import FormProvider from 'src/components/hook-form/form-provider';
import { TablePaginationCustom } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import { HEARING_COUNCILS_STATUS } from 'src/constants/hearing-councils-status';
import HearingCouncilCard from '../card/hearing-council-card';
import useHearingCouncils from './use-hearing-councils';
import { Order_By } from 'src/graphql';

const HearingCouncils = ({
  userId,
  noCouncilsView,
}: {
  userId?: string;
  noCouncilsView?: JSX.Element;
}) => {
  const {
    deleteHearingCouncil,
    handleSearch,
    hearingCouncils,
    isDeleting,
    isInUserDetails,
    isLoadingHearingCouncils,
    methods,
    openFilters,
    pagination,
    query,
    setQuery,
    t,
    openSort,
    FILTERS,
    SORT_OPTIONS,
    handleResetFilters,
    handleResetSort,
    isLoadingMutoons,
    mutoon,
  } = useHearingCouncils({ userId });

  if (
    !isLoadingHearingCouncils &&
    hearingCouncils?.hearing_councils_aggregate.aggregate?.count === 0 &&
    isInUserDetails
  ) {
    return <Card sx={{ py: 2, px: 2 }}>{noCouncilsView}</Card>;
  }

  const renderHearingCouncils = (
    <FormProvider methods={methods}>
      <Helmet>
        <title>{t('sidebar.hearingCouncils')}</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <AppTableToolbar
            handleSearch={handleSearch}
            setQuery={setQuery}
            tableName=""
            pagination={pagination}
            search={query.search!}
            buttons={
              !isInUserDetails
                ? [
                    {
                      permissionName: AppPermissions.HEARING_COUNCILS_CREATE,
                      path: 'new',
                    },
                  ]
                : [{}]
            }
            reset={handleResetFilters}
            resetSort={handleResetSort}
            filters={FILTERS}
            openFilters={openFilters}
            openSort={openSort}
            sortByOptions={SORT_OPTIONS}
          />
          <Divider />
          <Grid container columnSpacing={{ md: 2, xs: 0 }} rowSpacing={2}>
            {!isLoadingHearingCouncils
              ? hearingCouncils?.hearing_councils.map((hearingCouncil) => (
                  <Grid
                    key={hearingCouncil.hearingCouncilId}
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    width="100%"
                  >
                    <HearingCouncilCard
                      hearingCouncil={hearingCouncil!}
                      mutateDelete={deleteHearingCouncil}
                      isDeleting={isDeleting}
                    />
                  </Grid>
                ))
              : [...Array(pagination.rowsPerPage)].map((i, index) => (
                  <Grid key={index} item md={4} xs={12} display="flex" width="100%">
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: '100%',
                        height: 118,
                        borderRadius: 2,
                      }}
                    />
                  </Grid>
                ))}
          </Grid>
        </Stack>
      </Card>
      {hearingCouncils && (
        <TablePaginationCustom
          count={hearingCouncils?.hearing_councils_aggregate.aggregate?.count!}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          onPageChange={pagination.onChangePage}
          onRowsPerPageChange={pagination.onChangeRowsPerPage}
          rowsPerPageOptions={[6, 12]}
        />
      )}
    </FormProvider>
  );

  return isInUserDetails ? renderHearingCouncils : <Container>{renderHearingCouncils}</Container>;
};

export default HearingCouncils;
