// @mui
import { IconButton, Menu, MenuItem, TableCell, TableRow, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import AppPermissions from 'src/constants/app-permissions';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { MatnSectionDto, MatnSectionStateType } from 'src/types/matn-sections';
import { getLocalizedValue } from 'src/utils/get-localized-value';

// ----------------------------------------------------------------------

type Props = {
  row: MatnSectionDto;
  deleteHandler: (mutoonId: string) => void;
};

export default function MatnSectionsRow({ row, deleteHandler }: Props) {
  // #region States and Hooks
  const { t } = useLocales();
  const { state }: { state: MatnSectionStateType } = useLocation();
  const addDialog = useBoolean();
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === 'light';
  const router = useNavigate();
  const [isOpenMaxHeight, setOpenMaxHeight] = useState<null | HTMLElement>(null);
  // #endregion

  // #region Handlers
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMaxHeight(event.currentTarget);
  }, []);

  const handleMaxHeightClose = useCallback(() => {
    setOpenMaxHeight(null);
  }, []);
  // #endregion Handlers

  const OPTIONS: { label: string; onClick: any }[] = [
    {
      label: t('tables.matnSections.difficultWords'),
      onClick: () => router(`${row.mutoonSectionId}/difficult-words`, { state }),
    },
    {
      label: t('tables.matnSections.sounds'),
      onClick: () => router(`${row.mutoonSectionId}/sounds`),
    },
  ];

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {getLocalizedValue(row.name)}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          <div dangerouslySetInnerHTML={{ __html: getLocalizedValue(row.description) }} />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {moment(row.updatedAt).format('YYYY-MM-DD')}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.CONTENT_EDIT]}>
            <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
              <IconButton>
                <Link
                  to={row.mutoonSectionId}
                  style={{
                    color: isLight ? 'black' : 'white',
                  }}
                  state={{ ...state, matnSection: row }}
                >
                  <Iconify icon="fluent-mdl2:page-edit" width="27" height="27" color="#FFAB8B" />
                </Link>
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.CONTENT_DELETE]}>
            <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
              <React.Fragment>
                <IconButton
                  LinkComponent={RouterLink}
                  onClick={() => deleteHandler(row.mutoonSectionId)}
                >
                  <Iconify
                    icon="material-symbols:delete-outline"
                    width="27"
                    height="27"
                    color="#DDB6FF"
                  />
                </IconButton>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={isOpenMaxHeight}
                  onClose={handleMaxHeightClose}
                  open={Boolean(isOpenMaxHeight)}
                  slotProps={{
                    paper: {
                      sx: {
                        minWidth: '100px',
                        maxHeight: 48 * 4.5,
                      },
                    },
                  }}
                >
                  {OPTIONS.map((option) => (
                    <MenuItem key={option.label} onClick={option.onClick}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </React.Fragment>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
    </>
  );
}
