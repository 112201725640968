import { useTheme } from '@mui/material';
import { useChart } from 'src/components/chart';
import { useLocales } from 'src/locales';
import { HearingCouncilsChartsHookPropsType } from './type';

const useHearingCouncilsCharts = ({ data, isLoading }: HearingCouncilsChartsHookPropsType) => {
  // #region hooks and states
  const theme = useTheme();
  const { t } = useLocales();
  // #endregion hooks and states

  // #region Charts data
  const totalAttendance = data?.user_hearing_councils.length;

  const completedAttendanceCount = data?.user_hearing_councils.filter(
    (user) => user.durationAttendance === user.hearingCouncil.duration
  ).length;

  const uncompletedAttendanceCount = isNaN(totalAttendance! - completedAttendanceCount!)
    ? 0
    : totalAttendance! - completedAttendanceCount!;
  // #endregion Charts data

  // #region Charts config
  const colors = [
    [theme.palette.primary.light, theme.palette.primary.main],
    [theme.palette.warning.light, theme.palette.warning.main],
  ];

  const chartOptionsCheckIn = useChart({
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0][0] },
          { offset: 100, color: colors[0][1] },
        ] as any,
      },
    },
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: -9,
        bottom: -9,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize as string,
          },
        },
      },
    },
  });

  const CHART_SIZE = { width: 106, height: 106 };
  const CHARTS_INFO = [
    { value: completedAttendanceCount, label: t('hearingCouncils.analytics.completedAtt') },
    { value: uncompletedAttendanceCount, label: t('hearingCouncils.analytics.uncompletedAtt') },
  ];
  // #endregion Charts config

  return {
    CHARTS_INFO,
    totalAttendance,
    chartOptionsCheckIn,
    CHART_SIZE,
    t,
  };
};

export default useHearingCouncilsCharts;
