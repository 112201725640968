import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';
import { useDeleteTranslationMutation, useTranslationsByMatnSectionIdQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import AddNewCategoryDialog from 'src/sections/dialogs/add/AddNewCategoryDialog';
import MatnSectionDiffWordsRow from './matn-section-diff-words-row';

const MatnSectionDiffWords = () => {
  // #region States and hooks
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const addDialog = useBoolean();
  const queryClient = useQueryClient();
  const { matnSectionId } = useParams();
  const methods = useForm();
  const { t, allLangs } = useLocales();
  const TABLE_HEAD = (t: any) => [
    { id: '1', label: t('tables.matnSectionsDiffWords.headLabels.word') },
    ...allLangs.map((lang, index) => ({
      id: index + 2,
      label: t(`translationTypes.${lang.name}`),
    })),
    { id: 'newRandomId', label: t('tables.matnSectionsDiffWords.headLabels.actions') },
  ];
  // #endregion States and hooks

  // #region Services
  const { data, isLoading } = useTranslationsByMatnSectionIdQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    _eq: matnSectionId,
  });

  const { mutate, isLoading: isDeleting } = useDeleteTranslationMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['TranslationsByMatnSectionId'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteMatnSection = (translationId: string) => {
    mutate({ translationId });
  };
  // #endregion Handlers

  return (
    <Container>
      <Helmet>
        <title>{t('tables.matnSections.difficultWords')}</title>
      </Helmet>
      {/* <CustomBreadcrumbs
        links={[
          {
            name: t('breadcrumbs.mutoon'),
          },
          {
            name: t('breadcrumbs.categories'),
            href: paths.dashboard.mutoon.categories,
          },
          {
            name: categoryName,
            href: `${paths.dashboard.mutoon.categories}/${categoryId}`,
          },
          {
            name: levelName,
            href: `${paths.dashboard.mutoon.categories}/${categoryId}/${levelId}`,
          },
          {
            name: matnName,
            href: `${paths.dashboard.mutoon.categories}/${categoryId}/${levelId}/${matnId}`,
          },
          {
            name: matnSectionName,
          },
        ]}
      /> */}
      <Card>
        <FormProvider methods={methods}>
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom headLabel={TABLE_HEAD(t)} />
                <TableBody>
                  {isLoading || isDeleting ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.translations?.map((row) => (
                        <MatnSectionDiffWordsRow
                          key={row.translationId}
                          row={row}
                          deleteHandler={handleDeleteMatnSection}
                        />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.translations_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
        <AddNewCategoryDialog open={addDialog.value} onClose={addDialog.onFalse} />
      </Card>
    </Container>
  );
};

export default MatnSectionDiffWords;
