type TranslationObject = {
  [key: string]: string;
};

export function getLocalizedValue(obj: TranslationObject): string {
  // Get the currently selected language from localStorage
  const language: string = localStorage.getItem('i18nextLng')! || 'ar';

  // Check if the object has the desired language and return its value
  if (obj && obj[language]) {
    return obj[language];
  }

  // If the desired language is not available, return any filled value
  if (obj) {
    const fallbackValue = Object.values(obj).find((value) => value?.trim());
    if (fallbackValue) {
      return fallbackValue;
    }
  }

  // Return an empty string or default value if no valid value is found
  return '';
}
