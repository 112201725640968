import { yupResolver } from '@hookform/resolvers/yup';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Container, Dialog, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import AppButton from 'src/components/common/AppButton';
import NewAppButton from 'src/components/common/NewAppButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useSendEmailToContactMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useSchemas from 'src/schemas/hooks/useSchemas';

type ReplyToMsgMutationVariablesType = {
  response: string;
  contactId: string;
};

type ReplyToMsgDialogPropsType = {
  open: boolean;
  onClose: VoidFunction;
  message: ReplyToMsgMutationVariablesType;
};

const ReplyToMessageDialog = ({ open, onClose, message }: ReplyToMsgDialogPropsType) => {
  if (!open) return <></>;
  // #region States
  const addBookDialog = useBoolean();
  const { t } = useLocales();
  const confirm = useBoolean();
  const queryClient = useQueryClient();
  const { allLangs } = useLocales();
  const [dataToSend, setDataToSend] = useState<ReplyToMsgMutationVariablesType>();
  const { ReplyToMessageSchema } = useSchemas();
  // #endregion States

  let defaultValues: ReplyToMsgMutationVariablesType = {
    response: message.response || '',
    contactId: message.contactId || '',
  };

  // #region form
  const methods = useForm<ReplyToMsgMutationVariablesType>({
    defaultValues,
    resolver: yupResolver(ReplyToMessageSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: ReplyToMsgMutationVariablesType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );
  // #endregion form

  // #region Services
  const { mutate: reply, isLoading: isReplying } = useSendEmailToContactMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ContactUs'] });
      onClose();
      confirm.onFalse();
    },
  });
  // #endregion Services

  // #region Handlers
  const handleReply = () => {
    reply(dataToSend!);
  };
  // #endregion Handlers
  return (
    <>
      <Dialog
        open={open && !confirm.value && !addBookDialog.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {t('dialogs.replyToMsg.header')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 0, md: 2 }}>
                <Grid item xs={12}>
                  <Stack spacing={3} py={3}>
                    {/* Put fields here */}
                    <RHFTextField name="response" />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <NewAppButton type="submit" label={t('buttons.reply')} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AppButton
                    onClick={onClose}
                    label={t('buttons.cancel')}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </Container>
        </DialogContent>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.replyToMsg.confirmMessage')}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isReplying}
              onClick={handleReply}
            />
          </>
        }
      />
    </>
  );
};

export default ReplyToMessageDialog;
