import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider from 'src/components/hook-form';
import { RHFMultiSelect } from 'src/components/hook-form/rhf-select';
import {
  Mutation_RootUpdate_Questions_ManyArgs,
  useQuestionsQuery,
  useUpdateQuestionsMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { useAddQuestionsContext } from 'src/pages/dashboard/questions/add-new/context/questions-context';
import {
  AddQuestionsFromTheBankDialogProps,
  AddQuestionsFromTheBankMutationVariablesType,
} from 'src/types/questions';
import { getLocalizedValue } from 'src/utils/get-localized-value';

const AddQuestionsFromTheBankDialog = ({ open, onClose }: AddQuestionsFromTheBankDialogProps) => {
  if (!open) return <></>;
  // #region States
  const { t } = useLocales();
  const queryClient = useQueryClient();
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<Mutation_RootUpdate_Questions_ManyArgs>();
  const { methods: parentForm, questionsExamId } = useAddQuestionsContext();
  const matnId = parentForm.watch().matnId;
  // #endregion States

  const defaultValueUpdatesInit = (questionId: string) => ({
    _set: { examId: questionsExamId! },
    where: { questionId: { _eq: questionId } },
  });

  const defaultValues: AddQuestionsFromTheBankMutationVariablesType = {
    updates: [],
    questions: [],
    matnId: '',
  };

  // #region form
  const methods = useForm<AddQuestionsFromTheBankMutationVariablesType>({
    defaultValues,
  });

  const {
    fields: updates,
    remove: removeUpdate,
    append: addUpdate,
  } = useFieldArray({
    control: methods.control,
    name: 'updates',
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: Mutation_RootUpdate_Questions_ManyArgs) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );
  // #endregion form

  // #region Services
  const { mutate: assignQuestions, isLoading: isAssigning } = useUpdateQuestionsMutation({
    onSuccess: () => {
      onClose();
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['Questions'] });
    },
  });

  const { data: questions, isLoading } = useQuestionsQuery({
    matnId: matnId ? { _eq: matnId } : {},
    examId: { _neq: questionsExamId },
  });

  const questionsOptions = questions?.questions.map((question) => ({
    label: getLocalizedValue(question.questionText),
    value: question.questionId!,
  }));
  // #endregion Services

  // #region handlers
  const handleChooseQuestion = (e: any) => {
    const questionId = e.target.dataset.value;
    const isSelected = e.target.getAttribute('aria-selected') === 'true';
    // If no questionId, return
    if (!questionId) return;
    // If deselecting, remove update
    if (isSelected) {
      methods.setValue('updates', [
        ...methods
          .getValues('updates')
          .filter((update) => update.where.questionId._eq !== questionId),
      ]);
    } else {
      addUpdate(defaultValueUpdatesInit(questionId));
    }
    // Else, add
  };
  // #endregion handlers
  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {t('dialogs.addNewQuestion.addQuestionsFromTheBank')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <AppLoadingAndErrorWrapper isLoading={isLoading}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Stack py={1} spacing={5}>
                      <Stack spacing={1}>
                        <RHFMultiSelect
                          name="questions"
                          options={questionsOptions || []}
                          checkbox
                          chip
                          onClick={(e: any) => {
                            handleChooseQuestion(e);
                          }}
                        />
                      </Stack>
                      <Typography variant="caption" textAlign="center">
                        {t('dialogs.addNewQuestion.questionsBankNote')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppButton label={t('buttons.add')} isLoading={false} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppButton
                      onClick={onClose}
                      label={t('buttons.cancel')}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Container>
        </DialogContent>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isAssigning}
              onClick={() => {
                assignQuestions({ updates: [...dataToSend?.updates!] });
              }}
            />
          </>
        }
      />
    </>
  );
};

export default AddQuestionsFromTheBankDialog;
