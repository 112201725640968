import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Card, CardContent, CardHeader, Container, Grid } from '@mui/material';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Image from 'src/components/image/image';
import { Upload } from 'src/components/upload';
import useAddNewKnowledgeBase from './use-add-new-knowledge-base';
import { useLocales } from 'src/locales';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { Helmet } from 'react-helmet-async';

const AddNewKnowledgeBase = () => {
  const {
    isLoadingKnowledgeBase,
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    isUploadingImage,
    file,
    handleDropSingleFile,
    setFile,
    editKnowledgeBase,
    knowledgeBaseId,
    isEditing,
    confirm,
    isAdding,
    isUpdating,
    mutate,
    handleChangeTab,
    langsWithErrors,
  } = useAddNewKnowledgeBase();
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>{t('titles.addKnowledgeBase')}</title>
      </Helmet>

      <Container>
        <AppLoadingAndErrorWrapper
          isLoading={isLoadingKnowledgeBase}
          errorMessage={null}
          sx={{ height: 'auto' }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item container xs={6}>
                <Grid item xs={12} display="flex" gap={4} flexDirection="column">
                  <TranslationTabs
                    currentTabValue={currentTabValue}
                    handleChangeTab={handleChangeTab}
                    errors={langsWithErrors}
                  />

                  <Block label={t('tables.knowledgeBases.headLabels.title')}>
                    <RHFTextField name={`title.${currentTabValue}`} />
                  </Block>

                  <Block label={t('tables.knowledgeBases.headLabels.content')} required={false}>
                    <RHFTextField name={`content.${currentTabValue}`} multiline minRows={12} />
                  </Block>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Card>
                  <CardHeader title={t('dialogs.addNewKnowledgeBase.uploadImage')} />
                  <CardContent>
                    <AppLoadingAndErrorWrapper
                      sx={{ height: 'auto' }}
                      isLoading={isUploadingImage || isUpdating}
                      errorMessage={null}
                    >
                      <Upload
                        file={file}
                        onDrop={handleDropSingleFile}
                        uploadLabel="dialogs.addNewKnowledgeBase.uploadImage"
                        fileFieldName="cover_url"
                        error={!!methods.formState.errors.cover_url}
                        onDelete={() => {
                          setFile(null);
                          methods.setValue('cover_url', '');
                          editKnowledgeBase({
                            knowledgeBaseId,
                            ...methods.watch(),
                            cover_url: '',
                          });
                        }}
                        previewComponent={<Image src={methods.watch().cover_url} />}
                      />
                    </AppLoadingAndErrorWrapper>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              ></Grid>
              <Grid item xs={12} md={6}>
                <AppButton
                  label={isEditing ? t('buttons.edit') : t('buttons.add')}
                  isLoading={false}
                  type="submit"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
      </Container>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewKnowledgeBase;
