import { Card, Container, IconButton, Link, Stack, Typography } from '@mui/material';
import { Block } from 'src/components/Block';
import CarouselCenterMode from 'src/components/carousel/sections/carousel-center-mode';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import NewAppButton from 'src/components/common/NewAppButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';
import RHFRadioGroup from 'src/components/hook-form/rhf-radio-group';
import Iconify from 'src/components/iconify';
import { RADIO_OPTIONS } from 'src/constants/teacher-requests-status-options';
import { AuthorizedUsersQuery } from 'src/graphql';
import { useRouter } from 'src/routes/hooks';
import useTeacherRequestDetails from './use-teacher-request-details';

const TeacherRequestDetails = () => {
  const {
    SHOW_DATA,
    categories,
    confirm,
    currentLang,
    handleChangeStatus,
    isApproving,
    isLoading,
    isLoadingCategories,
    isRejecting,
    methods,
    onSubmit,
    t,
    userData,
  } = useTeacherRequestDetails();

  return (
    <Container>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <AppLoadingAndErrorWrapper isLoading={isLoading || isLoadingCategories}>
          <Stack spacing={5}>
            {/* Request info */}
            <Stack spacing={1}>
              <Typography variant="h5">{t('teacherRequestDetails.requestInfo.title')}</Typography>
              <Card sx={{ p: 2 }}>
                <Stack spacing={2}>
                  {SHOW_DATA.map((obj) => (
                    <Stack alignItems="center" spacing={1} direction={{ md: 'row', xs: 'column' }}>
                      <Typography variant="body1" fontWeight={500}>
                        {obj.label}:
                      </Typography>
                      <Typography variant="body2">{obj.value}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Card>
            </Stack>

            {/* Files */}
            {!!userData?.files.length && (
              <Stack spacing={1}>
                <Typography variant="h5">{t('teacherRequestDetails.files.title')}</Typography>
                {userData?.files.length! > 1 ? (
                  <CarouselCenterMode
                    data={userData?.files!}
                    CarouselItem={(props) => <CarouselItem {...props} />}
                  />
                ) : (
                  <Stack alignItems="center" justifyContent="center">
                    <CarouselItem {...userData?.files[0]!} />
                  </Stack>
                )}
              </Stack>
            )}

            {/* Files */}
            <Stack spacing={1}>
              <Typography variant="h5">
                {t('teacherRequestDetails.changeRequestStatus.title')}
              </Typography>
              <RHFRadioGroup
                name="status"
                options={RADIO_OPTIONS(t)}
                sx={{ display: 'flex', flexDirection: 'row' }}
              />
            </Stack>

            {/* Choose user categories */}
            {methods.watch().status === 'approved' && (
              <Stack spacing={1}>
                <Typography variant="h5">
                  {t('teacherRequestDetails.approve.categories')}
                </Typography>
                <RHFAutocomplete
                  name="categoriesIds"
                  multiple
                  size="small"
                  options={
                    categories?.categories.map((ctg) => ({
                      label: ctg.name[currentLang.value],
                      value: ctg.categoryId,
                    }))!
                  }
                />
              </Stack>
            )}

            {!(methods.watch().status === 'pending') && (
              <Block label={t('teacherRequestDetails.adminMessage')}>
                <RHFTextField name="adminMsg" size="small" />
              </Block>
            )}

            {/* Buttons */}
            <NewAppButton
              label={t('teacherRequestDetails.changeRequestStatus.title')}
              size="small"
              color={methods.watch().status === 'approved' ? 'primary' : 'error'}
              disabled={methods.watch().status === 'pending'}
              type="submit"
            />
          </Stack>
        </AppLoadingAndErrorWrapper>
      </FormProvider>

      {/* Confirm rejection */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t(
          `teacherRequestDetails.${
            methods.watch().status === 'approved' ? 'approve' : 'reject'
          }.areYouSure`
        )}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isRejecting || isApproving}
              onClick={handleChangeStatus}
            />
          </>
        }
      />
    </Container>
  );
};

export default TeacherRequestDetails;

const CarouselItem = (props: AuthorizedUsersQuery['authorized_users'][0]['files'][0]) => {
  const router = useRouter();
  return (
    <Card sx={{ p: 1 }}>
      <Stack alignItems="center" spacing={1}>
        <img
          src={props.url!}
          width={400}
          height={200}
          style={{ borderRadius: 8, objectFit: 'contain' }}
        />
        <Link href={props.url!} target="_blank">
          <IconButton color="info">
            <Iconify icon="system-uicons:external" />
          </IconButton>
        </Link>
      </Stack>
    </Card>
  );
};
