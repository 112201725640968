import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import { Block } from 'src/components/Block';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { RHFTextField } from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { useAddNewHearingCouncilContext } from '../context/add-new-hearing-council-context';

const CouncilDetails = () => {
  const { currentTabValue, handleChangeTab, langsWithErrors, requiredLangs, t, admins } =
    useAddNewHearingCouncilContext();

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <TranslationTabs
          currentTabValue={currentTabValue}
          handleChangeTab={handleChangeTab}
          errors={langsWithErrors}
          requiredLangs={requiredLangs}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Block label={t('dialogs.addNewHearingCouncil.sessionName')}>
          <RHFTextField name={`sessionName.${currentTabValue}`} />
        </Block>
      </Grid>
      <Grid item md={6} xs={12}>
        <Block label={t('dialogs.addNewHearingCouncil.sheikhName')}>
          {/* <RHFTextField name={`sheikhName.${currentTabValue}`} /> */}
          <RHFSelect name="sheikhName">
            {admins?.admins.map((admin) => <MenuItem value={admin.id}>{admin.username}</MenuItem>)}
          </RHFSelect>
        </Block>
      </Grid>
      <Grid item md={6} xs={12}>
        <Block label={t('dialogs.addNewHearingCouncil.description')}>
          <RHFTextField name={`description.${currentTabValue}`}  />
        </Block>
      </Grid>
    </Grid>
  );
};

export default CouncilDetails;
