import { Grid, Typography } from '@mui/material';
import { Block } from 'src/components/Block';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';
import { getLocalizedValue } from 'src/utils/get-localized-value';
import { useAddNewHearingCouncilContext } from '../context/add-new-hearing-council-context';
import { RHFMultiSelect } from 'src/components/hook-form/rhf-select';

const CouncilMatn = () => {
  const {
    methods,
    t,
    mutoonOptions,
    generalMutoonOptions,
    hasGotMtns,
    hasGotGeneralMatns,
    certificates,
  } = useAddNewHearingCouncilContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Block label={t(`dialogs.addNewHearingCouncil.matn`)}>
          <RHFAutocomplete
            name="mutoon"
            multiple
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={!hasGotMtns}
            options={
              mutoonOptions?.mutoon_categories.map((mtn) => ({
                label: getLocalizedValue(mtn.mutoon.name),
                id: mtn.mutoonId!,
              })) || []
            }
            onChange={(e, value) => {
              methods.setValue(
                'mutoonIds',
                (value as { id: string; label: string }[]).map((v) => v.id)
              );
              methods.clearErrors('mutoonIds');
            }}
            hasErrors={Boolean(methods.formState.errors.mutoonIds)}
          />
        </Block>
      </Grid>
      <Grid item xs={12} md={6}>
        <Block label={t(`dialogs.addNewHearingCouncil.generalMatn`)} required={false}>
          <RHFAutocomplete
            name="generalMutoon"
            multiple
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={!hasGotGeneralMatns}
            options={
              generalMutoonOptions?.mutoons.map((mtn) => ({
                label: getLocalizedValue(mtn.name),
                id: mtn.id!,
              })) || []
            }
            onChange={(e, value) => {
              methods.setValue(
                'generalMutoonIds',
                (value as { id: string; label: string }[]).map((v) => v.id)
              );
              methods.clearErrors('mutoonIds');
            }}
            hasErrors={Boolean(methods.formState.errors.mutoonIds)}
          />
        </Block>
      </Grid>
      {methods.formState.errors.mutoonIds?.message && (
        <Grid item xs={12}>
          <Typography color="error" variant="caption">
            {methods.formState.errors.mutoonIds?.message}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Block label={t('dialogs.addNewHearingCouncil.certificate')}>
          <RHFMultiSelect
            options={
              certificates?.certificates.map((crt) => ({
                label: crt.title!,
                value: crt.certificateId!,
              })) || []
            }
            name="certificateIds"
            checkbox
            chip
          />
        </Block>
      </Grid>
      {/* <Grid item xs={12} md={6}>
      <Block label={t('dialogs.addNewSession.prerequisites')}>
        <RHFMultiSelect
          name="prerequisiteIds"
          options={prerequisitesOptions || []}
          disabled={!hasGotMtns}
          chip
          checkbox
        />
      </Block>
    </Grid> */}
    </Grid>
  );
};

export default CouncilMatn;
