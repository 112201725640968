import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { RHFTextField } from 'src/components/hook-form';
import { TableProps, useTable } from 'src/components/table';
import { AppTablePropsType } from 'src/components/table/app-table';
import RowActions, { RowActionsPropsType } from 'src/components/table/table-row-actions';
import {
  HearingCouncilAnalysisQuery,
  useCreateCouncilCertificateMutation,
  useHearingCouncilAnalysisQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { default as UseSearch } from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { FinishedCouncilsFormType } from 'src/types/hearing-council';
import { orderBy } from 'src/utils/order-by';
import { handleNoCertificatesSelected } from '../utils/helper';

type RowType = HearingCouncilAnalysisQuery['user_hearing_councils'][0];

const useFinishedHearingCouncilsUsers = ({
  certificateHearingCouncilId,
}: {
  certificateHearingCouncilId: string;
}) => {
  // #region States and hooks
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const router = useNavigate();
  const { methods, query, handleSearch, setQuery } = UseSearch<FinishedCouncilsFormType>({});
  const [selectedRow, setSelectedRow] = useState<RowType>();
  const [index, setIndex] = useState<number>();
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const { hearingCouncilId } = useParams();
  const confirm = useBoolean();
  const confirmExportSelected = useBoolean();
  const isCertificatePreview = useBoolean();

  const {
    fields: selectedUsers,
    append: selectUser,
    remove: unselectUser,
  } = useFieldArray({
    control: methods!?.control,
    name: 'selectedUsers',
  });
  // #endregion States and hooks

  // #region Services
  const { mutate: createCert, isLoading: isCreatingCert } = useCreateCouncilCertificateMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['HearingCouncilAnalysis'] });
      confirm.onFalse();
      confirmExportSelected.onFalse();
      isCertificatePreview.value &&
        window.open(
          data.createUserHearingCouncilCertificate[0].userCertificate?.downloadPath!,
          '_blank'
        );
      // Return isCertificatePreview to false
      isCertificatePreview.onFalse();
      if (!data.createUserHearingCouncilCertificate.length) {
        enqueueSnackbar({
          message: t('tables.finishedHearingCouncilsAnalysis.createCertResWarning'),
          variant: 'warning',
        });
        if (data.createUserHearingCouncilCertificate.length) {
          enqueueSnackbar({
            message: t('snackbar.completedSuccessfully'),
            variant: 'success',
          });
        }
      }
    },
  });

  const { data, isLoading } = useHearingCouncilAnalysisQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    order_by: orderBy(methods.watch().sortBy!, methods.watch().sortDirection),
    hearingCouncilId: hearingCouncilId!,
    search: query.search ? { _ilike: `%${query.search}%` } : undefined,
    user: { _not: { school_user: { user: { isSchool: { _eq: true } } } } },
  });
  // #endregion Services

  // #region Handlers
  const handleExportSelectedCert = () => {
    if (!certificateHearingCouncilId) {
      handleNoCertificatesSelected(t);
    } else
      createCert({
        createCertficateInput: selectedUsers
          .filter((user) => {
            const isCertified =
              user.user_certificate?.userHearingCouncil?.hearingCouncil.hearingCouncilId;
            return !isCertified;
          })
          .map((user) => ({
            customName: user.customName,
            userHearingCouncilId: user.userHearingCouncilId,
            certificateHearingCouncilId,
          })),
      });
  };

  const handleExportUserCertificate = (
    index: number,
    row: RowType | undefined,
    isPreview: boolean = false
  ) => {
    if (row)
      createCert({
        createCertficateInput: [
          {
            userHearingCouncilId: row!?.userHearingCouncilId,
            customName: methods.watch().users!?.[index].customName,
            certificateHearingCouncilId,
            isPreview,
          },
        ],
      });
  };

  const handleSelectAllRows = (checked: boolean, table: TableProps, rows: RowType[]) => {
    table.onSelectAllRows(
      checked,
      rows.filter((uhc) => !uhc.user?.school_user).map((uhc) => uhc.userHearingCouncilId!)!
    );
    if (!checked) {
      console.log('uncheck');
      unselectUser();
    } else {
      selectUser(
        methods
          .watch()
          .users.filter((uhc) => !uhc.user?.school_user)
          .map((user, index) => ({
            ...user,
            userHearingCouncilId: user.userHearingCouncilId,
            customName: user.customName,
          }))
      );
    }
  };

  const handleSelectRow = (row?: RowType, table?: TableProps, index?: number) => {
    table?.onSelectRow(row?.userHearingCouncilId!);
    if (!table?.selected.includes(row?.userHearingCouncilId!)) {
      selectUser({
        ...row!,
        customName: methods.watch().users[index!].customName,
        userHearingCouncilId: row?.userHearingCouncilId!,
      });
    } else {
      unselectUser(index);
    }
  };
  // #endregion Handlers

  useEffect(() => {
    if (data) {
      methods.setValue(
        'users',
        data!?.user_hearing_councils.map((user) => ({
          ...user,
          customName: user.user?.firstName! + ' ' + user.user?.lastName!,
        }))
      );
    }
  }, [data]);

  const generateMenuOption = (row: RowType, index: number): RowActionsPropsType['options'] => {
    const userCertificate = row.user_certificate;
    const canBeCertified = !userCertificate?.id && row.hearingCouncil.status === 'Expired';

    const isPreviewingCertificate =
      isCreatingCert && selectedRow?.userHearingCouncilId === row.userHearingCouncilId;

    return [
      {
        tooltip: t('dialogs.addNewExam.certificate'),
        icon: 'fluent:certificate-16-regular',
        onClick: !certificateHearingCouncilId
          ? () => handleNoCertificatesSelected(t)
          : () => {
              setSelectedRow(row);
              setIndex(index);
              confirm.onTrue();
            },
        permission: canBeCertified,
      },
      {
        tooltip: t('tableLabels.preview'),
        icon: isPreviewingCertificate ? 'line-md:loading-loop' : 'material-symbols:preview',
        onClick: !certificateHearingCouncilId
          ? () => handleNoCertificatesSelected(t)
          : () => {
              // in order to check if this is the row that the certificate is being exported as preview for
              // in order not to show loading indictors for all rows
              setSelectedRow(row);
              // in order to open the certificate in another tab after success exportation
              isCertificatePreview.onTrue();
              handleExportUserCertificate(index!, row!, true);
            },
        permission: canBeCertified,
      },
      {
        tooltip: t('dialogs.addNewExam.certificate'),
        icon: 'mdi:eye-circle',
        onClick: () => {
          window.open(userCertificate?.downloadPath!, '_target');
        },
        permission: !!userCertificate,
      },
    ].filter((option) => (option!?.permission === undefined ? option : option!?.permission));
  };

  const columns: AppTablePropsType<RowType>['columns'] = [
    {
      name: 'user.firstName',
      PreviewComponent: (data, index) => {
        return <RHFTextField name={`users.${index}.customName`} />;
      },
    },
    {
      name: 'user.email',
    },
    {
      name: 'joinedAt',
      PreviewComponent: (data) => data.joinedAt && moment(data.joinedAt).format('YYYY-MM-DD'),
    },
    {
      name: 'durationAttendance',
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data) =>
        data.hearingCouncil.duration > 0 ? (
          <Typography color={data.durationAttendance ? '' : 'error'}>
            {+((+data.durationAttendance! / +data.hearingCouncil.duration) * 100).toFixed(2) || '0'}
            %
          </Typography>
        ) : (
          '-'
        ),
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data) =>
        data.attendanceType
          ? t(
              `tables.finishedHearingCouncilsAnalysis.${
                data.attendanceType?.toLowerCase() as 'online'
              }`
            )
          : '',
    },
    {
      name: 'isFullyAttend',
      PreviewComponent: (data, index) => (data.isFullyAttend ? '✅' : '❌'),
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data, index) => <RowActions options={generateMenuOption(data, index)} />,
    },
  ];

  const filters = [{}];

  const selectProps: AppTablePropsType<RowType>['select'] = {
    handleSelectRow: handleSelectRow!, // Not used here
    handleSelectAllRows: handleSelectAllRows!,
    numSelected: table.selected.length,
    rowCount: table.rowsPerPage,
    selectedRowsActions: [
      {
        icon: 'fluent:certificate-16-regular',
        color: '#FFAB8B',
        tooltip: 'Certify',
        handler: certificateHearingCouncilId
          ? confirmExportSelected.onTrue
          : () => handleNoCertificatesSelected(t),
      },
    ],
    idPath: 'userHearingCouncilId',
  };

  return {
    t,
    data,
    table: table,
    isLoading,
    isCreatingCert,
    columns: columns.filter((col) => col),
    filters,
    handleSearch,
    setQuery,
    query,
    methods,
    confirm,
    confirmExportSelected,
    handleExportSelectedCert,
    handleExportUserCertificate: () =>
      handleExportUserCertificate(index!, selectedRow!, isCertificatePreview.value),
    handleNoCertificatesSelected,
    selectProps,
  };
};

export default useFinishedHearingCouncilsUsers;
