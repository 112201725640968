import { CertificateBuilder } from '@misraj-sdk/certificate-generator-ui';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Container, Grid, TextField } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import NewAppButton from 'src/components/common/NewAppButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import useAddNewCertificate from './use-add-new-certificate';

const AddNewCertificate = () => {
  const {
    title,
    setTitle,
    t,
    STATIC_VARS,
    hasAddedImage,
    handleImageUpload,
    handleAddVariables,
    confirm,
    isAddingCert,
    isUploadingCert,
    addCertificate,
  } = useAddNewCertificate();

  return (
    <Container>
      <Helmet>
        <title>{t('sidebar.certificates.builder')}</title>
      </Helmet>
      <Grid container justifyContent="center" rowSpacing={2} columnSpacing={2}>
        <Grid item xs={2}>
          <Block label={t('dialogs.addNewExam.title')}>
            <TextField fullWidth value={title} onChange={(e) => setTitle(e.target.value)} />
          </Block>
        </Grid>
        <Grid item xs={12}>
          <CertificateBuilder
            height={600}
            width={600}
            staticVariables={STATIC_VARS}
            onCertificateFileAdded={(e) => {
              hasAddedImage.onTrue;
              handleImageUpload(e);
            }}
            onVariablesListChange={handleAddVariables}
          />
        </Grid>
        <Grid item md={2}>
          <NewAppButton
            label={t('dialogs.certificates.addNewCertificate')}
            onClick={confirm.onTrue}
            disabled={!hasAddedImage.value || !title}
            size="large"
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isAddingCert || isUploadingCert}
              onClick={() => addCertificate({ title: title! })}
            />
          </>
        }
      />
    </Container>
  );
};

export default AddNewCertificate;
