import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {
  Button,
  Container,
  Grid,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AppButton, { IAppButtonProps } from 'src/components/common/AppButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider from 'src/components/hook-form';
import { useAddNewHearingCouncilContext } from './context/add-new-hearing-council-context';
import AppStepper from 'src/components/common/app-stepper';

const AddNewHearingCouncil = () => {
  const {
    methods,
    handleSubmit,
    onSubmit,
    isEditing,
    isAdding,
    confirm,
    mutate,
    isUpdating,
    t,
    steps,
  } = useAddNewHearingCouncilContext();

  return (
    <>
      <Container>
        <Helmet>
          <title>{t('titles.addHearingCouncil')}</title>
        </Helmet>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <AppStepper
                steps={steps}
                isEditing={isEditing}
                hasFormBeenSubmitted={methods.formState.isSubmitted}
                mainButtonLabel={isEditing ? t('buttons.edit') : t('buttons.add')}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Container>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewHearingCouncil;
