import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { useTable } from 'src/components/table';
import {
  Order_By,
  useDeleteCategoryMutation,
  useDeleteMatnMutation,
  useMutoonCategoriesQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { findParentId, findParentIdString } from 'src/utils/mutoon-categories/find-last-id';
import { orderBy } from 'src/utils/order-by';
import { useQueryParam } from 'use-query-params';

const useCategoriesMutoon = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const addCtgDialog = useBoolean();
  const openFilters = useBoolean();
  const addMtnDialog = useBoolean();
  const addGeneralMtnDialog = useBoolean();
  const queryClient = useQueryClient();
  const { '*': splat } = useParams();
  const { t } = useLocales();

  const [onlyMutoons] = useQueryParam('onlyMutoons');

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc;
    onlyMutoons: boolean;
  }>({
    onlyMutoons: onlyMutoons === 'true' || false,
  });

  // #region Services
  const { data, isLoading } = useMutoonCategoriesQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    search: query.search ? { _cast: { String: { _ilike: `%${query.search}%` } } } : undefined,
    categoryCond: query.search ? {} : findParentId(splat),
    order_by: [
      // orderBy(methods.watch().sortBy, methods.watch().sortDirection),
      { mutoonId: Order_By.Asc },
      { createdAt: Order_By.Asc },
    ],
    order_ctgs_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
    order_mutoon_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
    exclude_ctgs: { _is_null: methods.watch().onlyMutoons },
    // Check if the user is in the first layer
    mutoonsWhere: !findParentIdString(splat)
      ? {
          categoryId: { _is_null: true },
          name: query.search ? { _cast: { String: { _ilike: `%${query.search}%` } } } : undefined,
        }
      : // If not in the first layer, do not return back mutoons from mutoons table because they are already returned
        // from mutoons_categories table (You can also check the query)
        { id: { _is_null: true } },
  });

  const allResultsCount =
    +data?.categories_aggregate.aggregate?.count! +
    +data?.mutoons_aggregate.aggregate?.count! +
    +data?.mutoon_categories_aggregate.aggregate?.count!;

  const { mutate: deleteCategory, isLoading: isDeletingCtg } = useDeleteCategoryMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] }),
  });

  const { mutate: deleteMatn, isLoading: isDeletingMatn } = useDeleteMatnMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteCategory = (categoryId: string) => {
    deleteCategory({ categoryId });
  };

  const handleDeleteMatn = (mutoonId: string) => {
    deleteMatn({ mutoonId });
  };
  // #endregion Handlers
  return {
    methods,
    openFilters,
    setQuery,
    handleSearch,
    query,
    addCtgDialog,
    addMtnDialog,
    addGeneralMtnDialog,
    isLoading,
    isDeletingCtg,
    isDeletingMatn,
    data,
    handleDeleteCategory,
    handleDeleteMatn,
    table,
    t,
    allResultsCount,
  };
};

export default useCategoriesMutoon;
