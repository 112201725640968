// @mui
import { Avatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import AppPermissions from 'src/constants/app-permissions';
import { UsersQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

type Props = {
  row: UsersQuery['users'][0] | undefined;
  deleteHandler: (mutoonId: string) => void;
};

export default function UsersRow({ row, deleteHandler }: Props) {
  const { t } = useLocales();
  const router = useNavigate();
  const { themeMode } = useSettingsContext();

  const handleEdit = () => {
    router(`${row!?.id}`, { state: row });
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ textAlign: 'center' }}>
          <Avatar src={row?.avatar} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row?.firstName}</TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>{row?.lastName}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {moment(row!?.createdAt).format('YYYY-MM-DD')}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row?.user_sessions_aggregate.aggregate?.count}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row?.users_user_hearing_councils_aggregate.aggregate?.count}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.KNOWLEDGE_BASES_EDIT]}>
            <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
              <IconButton onClick={handleEdit}>
                <Iconify icon="fluent-mdl2:page-edit" width="27" height="27" color="#FFAB8B" />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.USER_DELETE]}>
            <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row!?.id)}>
                <Iconify
                  icon="material-symbols:delete-outline"
                  width="27"
                  height="27"
                  color="#DDB6FF"
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
    </>
  );
}
