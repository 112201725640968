import englishJson from './en';

const arabicJson: typeof englishJson = {
  breadcrumbs: {
    mutoon: 'متون',
    categories: 'التصنيفات',
    levels: 'المستويات',
    books: 'الكتب',
  },
  snackbar: {
    keyCopiedToClipboard: 'تم نسخ المفتاح إلى الحافظة',
    completedSuccessfully: 'تمت العملية بنجاح',
  },
  constants: {
    dateOperators: {
      greaterThan: 'بعد',
      lessThan: 'قبل',
    },
    all: 'الكل',
  },
  translationTypes: {
    english: 'الإنكليزية',
    arabic: 'العربية',
    spanish: 'الإسبانية',
    isRequired: 'مطلوبة',
    doesNotMatch: 'القيمة المدخلة لا تتوافق مع اللغة التي تم اختيارها',
  },
  publicWords: {
    yes: 'نعم',
    no: 'لا',
  },
  filterDrawer: {
    filters: 'الفلاتر',
    resetButton: 'إلغاء الفلاتر',
  },
  tableLabels: {
    edit: 'تعديل',
    editQuestions: 'تعديل الأسئلة',
    delete: 'حذف',
    preview: 'عرض',
    approve: 'تصديق',
    reject: 'رفض',
  },
  examsStatus: {
    active: 'فعالة',
    inactive: 'غير فعالة',
  },
  buttons: {
    reply: 'إرسال الرد',
    cancel: 'إلغاء',
    add: 'إضافة',
    edit: 'تعديل',
    editMatnSectionAndAddNewWordsAndSounds: 'تعديل قسم المتن وإضافة الكلمات والأصوات الجديدة',
    subscribe: 'اشتراك',
    approve: 'تصديق',
    reject: 'رفض',
    showAnalysis: 'عرض التحليلات',
    reset: 'الرجوع إلى الوضع الافتراضي',
    submit: 'إرسال',
    download: 'تنزيل',
    startSharing: 'بدء المشاركة',
    next: 'التالي',
    prev: 'السابق',
  },
  days: {
    sun: 'الأحد',
    mon: 'الإثنين',
    tue: 'الثلاثاء',
    wed: 'الأربعاء',
    thu: 'الخميس',
    fri: 'الجمعة',
    sat: 'السبت',
  },
  questionsChoicesType: {
    count_errors: 'عد الأخطاء',
    is_there_error: 'هل هناك أخطاء',
    only_choose: 'يختار',
    where_the_error: 'أين الخطأ',
  },
  frequency: {
    once: 'مرة واحدة',
    daily: 'يومياً',
    weekly: 'أسبوعياً',
    monthly: 'شهرياً',
  },
  userRecords: {
    approved: 'مقبول',
    pending: 'معلق',
    rejected: 'مرفوض',
    all: 'الكل',
  },
  sidebar: {
    statistics: 'الإحصائيات',
    contactUs: 'تواصل معنا',
    certificates: {
      builder: 'مصمم الشهادات',
      table: 'جدول الشهادات',
      root: 'الشهادات',
    },
    faqs: 'أسئلة شائعة',
    sessions: {
      todaySessions: 'جلسات اليوم',
      upcomingSessions: 'الدورات القادمة',
      root: 'الجلسات',
    },
    mutoon: {
      categories: 'الفئات',
      exams: 'الامتحانات',
      root: 'متون',
      books: 'الكتب',
      questionsBank: 'بنك الأسئلة',
    },
    appUsers: 'المستخدمون',
    knowledgeBases: 'قواعد المعرفة',
    badges: 'الشارات',
    voiceRequests: 'الطلبات الصوتية',
    scientificSessions: 'جلسات علمية',
    hearingCouncils: 'مجالس الاستماع',
    discussionRoom: 'غرفة المناقشة',
    rolesAndUsers: 'الأدوار والمستخدمون',
    roles: 'الأدوار',
    users: 'مستخدمو لوحة التحكم',
    contentManagement: 'إدارة المحتوى',
    helpCenter: 'مركز الدعم',
    userAuthorization: {
      title: 'توثيق الحسابات',
      teachersRequests: 'طلبات المعلمين',
      usersCategories: 'تصنيفات المستخدمين',
      schoolRequests: 'طلبات المدارس',
      school: 'المدرسة',
    },
  },
  titles: {
    login: 'تسجيل الدخول',
    logout: 'تسجيل الخروج',
    mutoon: 'المتون',
    notFound: 'الصفحة غير موجودة',
    register: 'إنشاء حساب',
    addRole: 'أضف دوراً',
    mutoonAdminDashboard: 'متون - لوحة تحكم المشرف',
    addBadge: 'أضف شارة',
    addBook: 'أضف كتاباً',
    addExam: 'أضف امتحاناً',
    addFaq: 'أضف سؤالاً شائعاً',
    addHearingCouncil: 'أضف مجلساً سماعياً',
    addKnowledgeBase: 'أضف قاعدة معرفة',
    addQuestion: 'أضف سؤالاً',
    addSession: 'أضف جلسة',
    hearingCouncilAnalysis: 'تحليلات المجالس السماعية',
  },
  tables: {
    search: 'ابحث',
    exportData: 'تصدير البيانات',
    rowsPerPageLabel: 'سطر في الصفحة',
    sort: {
      sortDirection: 'طريقة الترتيب',
      ascending: 'تصاعدياً',
      descending: 'تنازلياً',
      sortBy: 'فرز حسب',
    },
    categories: {
      addNew: 'أضف تصنيفاً جديداً',
      type: 'تصنيف',
      headLabels: {
        name: 'الاسم',
        type: 'النوع',
        actions: 'التأثيرات',
        date: 'التاريخ',
      },
      filters: {
        onlyMutoon: 'متون فقط',
      },
    },
    faqs: {
      addNew: 'إضافة سؤال',
      headLabels: {
        actions: 'التأثيرات',
        answer: 'الإجابة',
        question: 'السؤال',
        createdAt: 'أنشئ في',
      },
    },
    badges: {
      headLabels: {
        badgeName: 'اسم الشارة',
        count: 'عدد المرات',
        badgeImage: 'صورة الشارة',
        createdAt: 'أنشئت في',
        lastUpdated: 'آخر تحديث',
        actions: 'التأثيرات',
        badgeType: 'نوع الشارة',
      },
    },
    levels: {
      addNew: 'أضف مستوى جديداً',
      headLabels: {
        actions: 'التأثيرات',
        date: 'التاريخ',
        nameOfLevel: 'اسم المستوى',
      },
    },
    mutoon: {
      addNew: 'أضف متناً جديداً',
      addNewGeneral: 'أضف متناً عاماً جديداً',
      headLabels: {
        actions: 'التأثيرات',
        date: 'التاريخ',
        nameOfMatn: 'اسم المتون',
        description: 'الوصف',
        numberOfHadith: 'عدد الأحاديث',
        isGeneral: 'متن عام',
      },
    },
    books: {
      addNew: 'أضف كتاباً',
      headLabels: {
        bookAuthor: 'المؤلف',
        bookName: 'اسم الكتاب',
        actions: 'التأثيرات',
        date: 'التاريخ',
      },
    },
    matnSections: {
      addNew: 'أضف مقسم جديداً',
      difficultWords: 'الكلمات الصعبة',
      sounds: 'الأصوات',
      headLabels: {
        name: 'اسم القسم',
        description: 'الوصف',
        actions: 'التأثيرات',
        date: 'التاريخ',
      },
    },
    matnSectionsDiffWords: {
      headLabels: {
        word: 'الكلمة',
        actions: 'التأثيرات',
      },
    },
    matnSectionSounds: {
      headLabels: {
        name: 'الاسم',
        description: 'الوصف',
        preview: 'عرض',
        actions: 'التأثيرات',
      },
    },
    voiceRequests: {
      headLabels: {
        userName: 'اسم المستخدم',
        userEmail: 'البريد الإلكتروني',
        sendingDate: 'تاريخ الإرسال',
        nameOfMatn: 'اسم المتن',
        preview: 'عرض الملف',
        status: 'الحالة',
        actions: 'التأثيرات',
      },
    },
    dashboardUsers: {
      headLabels: {
        name: 'الاسم',
        role: 'الدور',
        actions: 'التأثيرات',
        email: 'البريد الإلكتروني',
      },
      addNew: 'أضف مستخدماً جديداً',
    },
    exams: {
      headLabels: {
        title: 'العنوان',
        description: 'الوصف',
        matn: 'المتن',
        actions: 'التأثيرات',
      },
      addNew: 'إضافة فحص جديد',
    },
    questions: {
      headLabels: {
        question: 'السؤال',
        matn: 'المتن',
        matnSection: 'قسم المتن',
        questionChoices: 'خيارات السؤال',
        questionType: 'نوع السؤال',
      },
      addNew: 'إضافة سؤال',
      filters: {
        choicesType: 'نوع الاختيار',
        matn: 'المتن',
        isQuiz: 'يعود إلى',
        enums: {
          isQuiz: {
            exam: 'امتحان',
            quiz: 'مذاكرة',
          },
        },
      },
    },
    knowledgeBases: {
      headLabels: {
        title: 'العنوان',
        content: 'المحتوى',
        actions: 'التأثيرات',
        coverUrl: 'الغلاف',
        createdAt: 'أنشئت في',
      },
    },
    certificates: {
      headLabels: {
        title: 'العنوان',
        createdAt: 'أنشئت في',
        image: 'الصورة',
        actions: 'التأثيرات',
      },
    },
    categoryMutoon: {
      type: 'متن',
    },
    finishedHearingCouncilsAnalysis: {
      headLabels: {
        attendanceRate: 'معدل الحضور',
        email: 'البريد الإلكتروني',
        attendanceDuration: 'مدة الحضور',
        name: 'الاسم',
        registeredIn: 'دخل في',
        joinType: 'نوع الدخول',
        isCompletelyAttended: 'حضر بشكل كامل',
      },
      students: 'الطلاب',
      online: 'بواسطة الشابكة',
      onsite: 'من الموقع',
      createCertResWarning: 'لم يتم تصدير أية شهادات',
      alertMessage: 'الرجاء القيام باختيار شهادة أولاً',
      tabs: {
        schools: 'المدارس',
        users: 'المستخدمون',
      },
    },
    contactUs: {
      headLabels: {
        name: 'الاسم',
        status: 'الحالة',
        problem: 'المشكلة',
        email: 'البريد الإلكتروني',
        date: 'التاريخ',
        actions: 'التأثيرات',
      },
      status: {
        active: 'معلقة',
        inactive: 'تم الرد عليها',
        replied: 'تم الرد عليها',
      },
      filters: {
        createdAt: 'أرسلت في',
        status: 'الحالة',
      },
    },
    roles: {
      headLabels: {
        users: 'المستخدمون',
        roleName: 'اسم الدور',
        actions: 'التأثيرات',
      },
    },
    users: {
      headLabels: {
        firstName: 'الاسم الأول',
        lastName: 'الاسم الأخير',
        createdAt: 'سجل في',
        numberOfSessions: 'عدد الجلسات',
        numberOfHearingCouncils: 'عدد المجالس',
        actions: 'التأثيرات',
      },
    },
    teachersRequests: {
      headLabels: {
        actions: 'التأثيرات',
        createdAt: 'تاريخ التقدم',
        fieldOfStudy: 'مجال الدراسة',
        filesCount: 'عدد الملفات',
        firstName: 'الاسم الأول',
        lastName: 'الاسم الأخير',
        status: 'الحالة',
      },
    },
    schoolRequests: {
      headLabels: {
        actions: 'التأثيرات',
        city: 'المدينة',
        createdAt: 'تاريخ إرسال الطلب',
        schoolAddress: 'عنوان المدرسة',
        schoolName: 'اسم المدرسة',
        status: 'الحالة',
        filesCount: 'عدد الملفات',
        country: 'البلد',
      },
    },
    students: {
      headLabels: {
        actions: 'التأثيرات',
        birthDate: 'تاريخ الميلاد',
        email: 'البريد الإلكتروني',
        firstName: 'الاسم الأول',
        lastName: 'الاسم الأخير',
        firstNameEn: 'الاسم الأول بالإنكليزي',
        lastNameEn: 'الاسم الأخير بالإنكليزي',
      },
    },
  },
  dialogs: {
    replyToMsg: {
      header: 'قم بالرد على الاستفسارات',
      confirmMessage: 'هل أنت متأكد من أنك تريد إرسال هذه الرسالة؟',
    },
    accountPopover: {
      home: 'الصفحة الرئيسية',
      logout: 'تسجيل الخروج',
      profile: 'الملف الشخصي',
      settings: 'الإعدادات',
    },
    settingsDrawer: {
      contrast: 'التباين',
      direction: 'الاتجاه',
      layout: 'التخطيط',
      mode: 'الوضع',
      presets: 'الأنماط المسبقة',
      settings: 'الإعدادات',
      stretch: 'التمدد',
      fullscreen: 'شاشة كاملة',
    },
    addNewKnowledgeBase: {
      uploadImage: 'ارفع صورة',
    },
    rejectRecord: {
      header: 'رفض التسجيل',
      adminMessage: 'رسالة الأدمن',
    },
    addNewCategory: {
      name: 'الاسم',
      description: 'الوصف',
      header: 'أضف تصنيف جديداً',
    },
    addNewLevel: {
      name: 'الاسم',
      description: 'الوصف',
      header: 'أضف مستوى جديداً',
    },
    addNewMatn: {
      name: 'الاسم',
      description: 'الوصف',
      PDF: 'PDF',
      addNewBook: 'أضف كتاباً جديداً',
      numberOfHadith: 'عدد الأحاديث',
      header: 'أضف متناً جديداً',
    },
    addNewBook: {
      title: 'عنوان الكتاب',
      bookName: 'اسم الكتاب',
      bookAuthor: 'المؤلف',
      bookLanguage: 'لغة الكتاب',
      fromSchool: 'المدرسة',
      nazemName: 'اسم الناظم',
      dateOfBorn: 'تاريخ الميلاد',
      dateOfDeath: 'تاريخ الوفاة',
      numberOfLines: 'عدد الأسطر',
      uploadPdf: 'تحميل كتاب إلكتروني',
      header: 'أضف كتاباً جديداً',
    },
    addNewHearingCouncil: {
      sheikhName: 'اسم المشرف',
      sessionName: 'اسم الجلسة',
      description: 'الوصف',
      startDate: 'تاريخ البدء',
      expiryDate: 'تاريخ النهاية',
      category: 'الصنف',
      level: 'المرحلة',
      matn: 'المتن',
      price: 'الثمن',
      appointmentLimit: 'حد الموعد (دقائق)',
      accordions: {
        councilDetails: 'تفاصيل المجلس',
      },
      autoCertificate: 'شهادة تلقائية',
      certificate: 'شهادة',
      generalMatn: 'متن عام',
      errorMessages: {
        appointmentLimit: {
          lessThanCouncilTime: 'حد الموعد ينبغي أن يكون أقل من وقت المجلس',
        },
        expiryTime: 'وقت النهاية ينبغي أن يكون بعد وقت البدء',
      },
    },
    addNewSession: {
      title: 'العنوان',
      description: 'الوصف',
      startDate: 'تاريخ البدء',
      expiryDate: 'تاريخ النهاية',
      category: 'الصنف',
      level: 'المرحلة',
      matn: 'المتن',
      enableConversation: 'تفعيل المحادثة',
      enableRaiseHand: 'تفعيل رفع اليد',
      enableRecording: 'تفعيل التسجيل',
      enableUploadFiles: 'تفعيل رفع الملفات',
      enableVideo: 'تفعيل الفيديو',
      prerequisite: 'المتطلبات السابقة',
      days: 'الأيام',
      frequency: 'التكرار',
      maxUserCount: 'العدد الأعلى للمستخدمين',
      duration: 'المدة (دقائق)',
      prerequisites: 'المتطلبات المسبقة',
      accordions: {
        sessionPreferences: 'تفضيلات الجلسة',
        dateAndTime: 'الوقت والتاريخ',
        matnAndCertificates: 'المتون والشهادات',
        certificateDetails: 'تفاصيل الشهادة',
        matnDetails: 'تفاصيل المتن',
      },
    },
    addNewMatnSection: {
      name: 'الاسم',
      description: 'الوصف',
      matnSectionDetails: 'تفاصيل قسم المتون',
      difficultWordsAndTheirMeanings: 'الكلمات الصعبة ومعانيها',
      addAnotherSection: 'أضف قسماً جديداً',
      addAnotherSound: 'أضف صوتاً جديداً',
      addAnotherWord: 'أضف كلمة جديدة',
      sound: 'الصوتيات',
      sheikName: 'اسم المشرف',
      uploadSound: 'تحميل ملف صوتي',
      save: 'حفظ',
      cancel: 'إلغاء',
      word: 'الكلمة',
      itsMeaning: 'معناها',
      addDifficultWordsAndTheirMeanings: 'أضف الكلمات الصعبة ومعانيها',
      addSounds: 'أضف ملف صوتي',
    },
    addNewSound: {
      name: 'الاسم',
      description: 'الوصف',
    },
    editDiffWord: {
      header: 'تعديل الترجمة',
    },
    editSound: {
      header: 'تعديل الصوت',
    },
    confirmMessage: 'هل أنت متأكد أنك تريد {{editOrAdd}}؟',
    confirmDeleteMessage: 'هل أنت متأكد أنك تريد أن تحذف؟',
    addNewAdmin: {
      userName: 'اسم المستخدم',
      email: 'البريد الإلكتروني',
      password: 'كلمة المرور',
      confirmPassword: 'تأكيد الكلمة',
      role: 'الدور',
    },
    certificates: {
      addNewCertificate: 'أضف شهادة',
      addVariables: 'أضف متغيراً',
    },
    addNewExam: {
      certificate: 'الشهادة',
      description: 'الوصف',
      duration: 'المدة',
      gradeA: 'الدرجة أ',
      gradeB: 'درجة ب',
      gradeC: 'الدرجة ج',
      gradeD: 'الدرجة د',
      gradeF: 'درجة الرسوب',
      minimumScore: 'الحد الأدنى من النتيجة',
      limit: 'الحد',
      maxAttempts: 'أكبر عدد للمحاولات',
      category: 'الصنف',
      level: 'المستوى',
      matn: 'المتن',
      status: 'الحالة',
      title: 'العنوان',
      moreDetails: 'المزيد من التفاصيل',
    },
    addNewQuestion: {
      correctAnswer: 'الجواب الصحيح',
      explanation: 'الشرح',
      hint: 'مساعدة',
      isQuiz: 'تابع لمذاكرة',
      matnSection: 'قسم المتن',
      points: 'النقاط',
      questionText: 'نص السؤال',
      choice: 'اختيار',
      addAnotherQuestion: 'إضافة سؤال جديد',
      addQuestionsFromTheBank: 'أضف من بنك الأسئلة',
      questionsBankNote:
        'ملحوظة: هذه القائمة تعرض لك الأسئلة التي تنتمي إلى نفس متن الامتحان والتي لم يتم إدراجها بالفعل',
      examName: 'اسم المذاكرة',
      choices: 'الخيارات',
      chooseType: 'نوع الاختيار',
    },
    addNewRole: {
      roleName: 'اسم الدور',
      roleInfo: 'معلومات الدور',
      permissions: 'الأذونات',
      enterRoleName: 'أدخل اسم الدور',
      actionsTable: {
        create: 'إنشاء',
        delete: 'حذف',
        edit: 'تعديل',
        systemCriteria: 'معايير النظام',
        view: 'عرض',
        rows: {
          Admins: 'المشرفون',
          Badges: 'الأوسمة',
          Categories: 'التصنيفات',
          Certificates: 'الشهادات',
          'Contact Us': 'تواصل معنا',
          Content: 'المحتوى',
          'Content Pages': 'صفحات المحتوى',
          'Content Recommendations': 'اقتراحات المحتوى',
          Dashboard: 'لوحة التحكم',
          'Discussion Rooms': 'غرف المناقشة',
          Donations: 'التبرعات',
          Exams: 'المذاكرات',
          'Hearing Council': 'مجلس السماع',
          'Hearing Councils': 'مجالس السماع',
          'Knowledge Bases': 'قواعد المعرفة',
          Notifications: 'الإشعارات',
          'Payment Methods': 'طرق الدفع',
          'Ratings and Reviews': 'التقييمات والمراجعات',
          'Reference Books': 'الكتب المرجعية',
          Roles: 'الأدوار',
          Session: 'الجلسة',
          Sessions: 'الجلسات',
          Sounds: 'الأصوات',
          Statistics: 'الإحصائيات',
          User: 'المستخدمون',
          'User Credit Card Infos': 'معلومات البطاقة الائتمانية للمستخدم',
          'User Record': 'تسجيلات المستخدم',
        },
      },
    },
  },
  login: {
    mtoun: 'متون',
    idealChoice: 'الخيار الأمثل لحفظ المتون',
    form: {
      button: 'تسجيل الدخول',
      email: {
        label: 'البريد الإلكتروني',
        placeholder: 'أدخل البريد الإلكتروني',
      },
      password: {
        label: 'كلمة المرور',
        placeholder: 'أدخل كلمة المرور',
      },
      welcomeMessage: 'أهلاً بك في تطبيق متون',
    },
  },
  // Hearing councils
  hearingCouncils: {
    viewPage: {
      header: 'الجلسات الاستماعية',
      search: 'بحث',
      newHearingCouncil: 'أضف جلسة استماعية',
    },
    joinPage: {
      endCall: 'إنهاء الاجتماع',
      leaveMeeting: 'مغادرة الاجتماع',
      startSharing: 'بدء المشاركة',
      connectedUsers: 'المستخدمون المتصلون',
      confirmEnd: 'هل أنت متأكد من أنك تريد إنهاء هذه الجلسة لكل المستخدمين؟',
    },
    filters: {
      expiryDate: 'تاريخ النهاية',
      startDate: 'تاريخ البداية',
      status: 'الحالة',
    },
    analytics: {
      completedAtt: 'الحضور الكامل',
      uncompletedAtt: 'الحضور غير الكامل',
      actualDuration: 'المدة الفعلية',
    },
    card: {
      edit: 'تعديل',
      startNow: 'ابدأ الآن',
      startsAt: 'يبدأ في',
      startedAt: 'بدأ في',
      qrCode: 'رمز QR',
      symbols: {
        hours: 'س',
        minutes: 'د',
      },
    },
    status: {
      Expired: 'منتهية',
      Live: 'فعالة',
      Pending: 'معلقة',
    },
  },
  badges: {
    types: {
      category: 'الأصناف',
      hearingCouncils: 'مجالس السماع',
      matn: 'المتون',
    },
    uploadImage: 'حمل صورة الشارة',
    tabs: {
      general: 'عامة',
      custom: 'مخصصة',
    },
    generalTypes: {
      hearingCouncilParticipation: 'المشاركة في المجالس السماعية',
      mutoonRecordContribution: 'المساهمة في التسجيلات الصوتية',
      sessionParticipation: 'المشاركة في الجلسات',
      streakDays: 'الأيام الممتالية',
      userSharesCount: 'مرات القيام بالمشاركة',
    },
  },
  // Sessions
  sessions: {
    viewPage: {
      header: 'الجلسات',
      search: 'بحث',
      newSession: 'أضف جلسة',
    },
    card: {
      edit: 'تعديل',
      startNow: 'ابدأ الآن',
      startsAt: 'يبدأ في',
      startedSince: 'بدأت منذ',
      join: 'تسجيل الدخول',
      startsIn: 'تبدأ في',
      endedSince: 'انتهت منذ',
      endsIn: 'تنتهي في',
      canStartIn: 'يمكن البدء في',
      date: 'التاريخ',
      repeatedOn: 'مكرر في',
      time: 'الوقت',
      weekly: 'في كل أيام الأسبوع',
    },
    status: {
      Expired: 'منتهية',
      Live: 'فعالة',
      Pending: 'معلقة',
      Paused: 'لا يمكن استئنافها اليوم',
    },
  },
  userDetails: {
    tabs: {
      aboutUser: 'عن المستخدم',
      sessions: 'الجلسات',
      hearingCouncils: 'المجالس السماعية',
    },
    aboutUser: {
      name: 'الاسم',
      email: 'البريد الإلكتروني',
      phone: 'رقم الهاتف',
      registerationInfo: 'معلومات التسجيل',
    },
    dontaions: 'التبرعات',
    sessions: {
      title: 'الجلسات',
      noSessions: {
        title: 'لا يوجد جلسات بعد',
        subtitle: 'لم يقم المستخدم بمتابعة أو إنشاء أي جلسات بعد',
      },
    },
    hearingCouncils: {
      title: 'المجالس السماعية',
      noHearingCouncils: {
        title: 'لا يوجد مجالس بعد',
        subtitle: 'لم يقم المستخدم بمتابعة أو إنشاء أي مجالس سماعية بعد',
      },
    },
  },
  statistics: {
    hearingCouncils: {
      createdCouncils: 'المجالس المنشأة',
      currentCouncils: 'المجالس الحالية',
      expiredCouncils: 'المجالس المنتهية',
      upcomingCouncils: 'المجالس القادمة',
      title: 'المجالس الخاصة باليوم',
      noCouncilsToday: 'لا توجد جلسات اليوم',
    },
    sessions: {
      createdSessions: 'الجلسات المنشأة',
      currentSessions: 'الجلسات الحالية',
      expiredSessions: 'الجلسات المنتهية',
      upcomingSessions: 'الجلسات القادمة',
      title: 'الجلسات الخاصة باليوم',
      noSessionsToday: 'لا توجد جلسات اليوم',
    },
    users: {
      table: {
        avatar: 'الصورة الشخصية',
        email: 'البريد الإلكتروني',
        joined: 'تاريخ الانضمام',
        name: 'الاسم',
      },
      lastJoiningUsers: 'آخر المستخدمين تسجيلاً',
    },
    certificates: {
      givenCertificatesByDate: 'إحصائيات الشهادات المعطاة بحسب التاريخ',
    },
    faqs: {
      title: 'الأسئلة الشائعة',
    },
    contactUs: {
      title: 'أسئلة المستخدمين',
    },
  },
  notFound: {
    title: 'عذراً, لم نعثر على الصفحة',
    subtitle:
      'عذراً, لم نتمكن من العثور على الصفحة التي تبحث عنها. ربما أخطأت في كتابة عنوان الصفحة؟. تحقق من كتابتك',
    goToHome: 'العودة إلى الصفحة الرئيسية',
  },
  teacherRequestDetails: {
    approve: {
      categories: 'اختر التصنيفات',
      areYouSure: 'هل أنت متأكد أنك تريد قبول المستخدم كمعلم؟',
    },
    changeRequestStatus: {
      approve: 'قبول',
      reject: 'رفض',
      pending: 'معلق',
      title: 'تعديل حالة الطلب',
    },
    reject: {
      adminMsg: 'رسالة المشرف',
      areYouSure: 'هل أنت متأكد أنك تريد رفض طلب ترقية الحساب لهذا المستخدم؟',
    },
    requestInfo: {
      title: 'معلومات الطلب',
    },
    files: {
      title: 'الملفات',
    },
    adminMessage: 'رسالة المشرف',
  },
  schoolRequestDetails: {
    approve: {
      areYouSure: 'هل أنت متأكد أنك تريد قبول هذا الحساب كحساب مدرسي؟',
    },
    changeRequestStatus: {
      approve: 'قبول',
      reject: 'رفض',
      pending: 'معلق',
      title: 'تعديل حالة الطلب',
    },
    reject: {
      areYouSure: 'هل أنت متأكد أنك تريد رفض طلب حساب المدرسة هذا؟',
    },
    requestInfo: {
      title: 'معلومات الطلب',
    },
    files: {
      title: 'الملفات',
    },
  },
};
export default arabicJson;
