import { yupResolver } from '@hookform/resolvers/yup';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Container, Dialog, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { serialize } from 'object-to-formdata';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { API_URLS } from 'src/api/apiUrls';
import useApiServices from 'src/api/useApiServices';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Upload } from 'src/components/upload';
import AudioPreview from 'src/components/upload/PreviewAudio';
import { useUpdateSoundMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { EditSoundDialogPropsType, EditSoundMutationVariablesType } from 'src/types/edit-sound';

type OptionType = {
  value: string;
  label: string;
};
const EditSoundDialog = ({ open, onClose, sound }: EditSoundDialogPropsType) => {
  if (!open) return <></>;
  // #region States
  const addBookDialog = useBoolean();
  const { t } = useLocales();
  const { categoryId, levelId } = useParams();
  const confirm = useBoolean();
  const { allLangs } = useLocales();
  const { useMultiPostApi } = useApiServices();
  const queryClient = useQueryClient();
  const [dataToSend, setDataToSend] = useState<EditSoundMutationVariablesType>();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const { soundEditSchema } = useSchemas();
  // #endregion States

  let defaultValues: EditSoundMutationVariablesType = {
    name: sound.name || '',
    description: sound.description || '',
    soundId: sound.soundId || '',
    fileUrl: sound.fileUrl || '',
  };

  // #region form
  for (let i = 0; i < allLangs.length; i++) {
    defaultValues.name[allLangs[i].value] = sound.name[allLangs[i].value] || '';
    defaultValues.description[allLangs[i].value] = sound.description[allLangs[i].value] || '';
  }

  const methods = useForm<EditSoundMutationVariablesType>({
    defaultValues,
    resolver: yupResolver(soundEditSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: EditSoundMutationVariablesType) => {
      delete data.fileUrl;
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = Object.values(errors)
    .map((errors) => Object.keys(errors))
    .flat()
    .filter((value, index, array) => array.indexOf(value) !== index);
  // #endregion form

  // #region Services
  const { mutate: uploadSoundFile, isLoading: isUploadingSound } = useMultiPostApi({
    url: API_URLS.UPLOAD_SOUND,
    onSuccess: confirm.onFalse,
  });

  const {
    mutate,
    isLoading: isUpdating,
    isSuccess,
  } = useUpdateSoundMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['SoundsByMatnSectionId'] });
      // If the form doesn't have a sound file, just close the confirm and edit dialog
      if (!methods.watch().fileUrl) {
        confirm.onFalse();
        onClose();
        // If it has a sound file, upload this sound to the database
      } else {
        uploadSoundFile({
          data: serialize(methods.watch().fileUrl, {}, undefined, 'file'),
          id: data.update_sounds_by_pk?.soundId,
        });
      }
    },
  });
  // #endregion Services

  // #region Handlers
  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      methods.setValue(
        `fileUrl`,
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);
  // #endregion Handlers

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers
  // #region useEffect
  useEffect(() => {
    methods.setValue(`name.${currentTabValue}`, methods.watch().name[currentTabValue] || '');
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);
  // #endregion useEffect
  return (
    <>
      <Dialog
        open={open && !confirm.value && !addBookDialog.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {t('dialogs.editSound.header')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <TranslationTabs
              handleChangeTab={handleChangeTab}
              currentTabValue={currentTabValue}
              errors={langsWithErrors}
            />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 0, md: 2 }}>
                <Grid item xs={12}>
                  <Stack spacing={3} py={3}>
                    <Block label={t('dialogs.addNewSound.name')}>
                      <RHFTextField name={`name.${currentTabValue}`} />
                    </Block>
                    <Block label={t('dialogs.addNewSound.description')}>
                      <RHFTextField name={`description.${currentTabValue}`} />
                    </Block>
                  </Stack>
                  <Upload
                    file={methods.watch().fileUrl}
                    onDrop={(e: any) => handleDropSingleFile(e)}
                    icon="solar:soundwave-bold"
                    uploadLabel={t('dialogs.addNewMatnSection.uploadSound')}
                    onDelete={() => {
                      methods.setValue(`fileUrl`, '');
                    }}
                    iframeStyle={{
                      height: '100%',
                    }}
                    previewComponent={
                      <AudioPreview
                        src={methods.watch().fileUrl!?.preview! || methods.watch().fileUrl}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AppButton
                    label={t('buttons.edit')}
                    fullWidth
                    onClick={() => onSubmit(methods.watch())}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AppButton
                    onClick={onClose}
                    label={t('buttons.cancel')}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </Container>
        </DialogContent>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: t('buttons.edit').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isUpdating}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default EditSoundDialog;
