// @mui
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useQueryClient } from '@tanstack/react-query';
// components
import moment from 'moment';
import { Link } from 'react-router-dom';
import AppButton from 'src/components/common/AppButton';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import TextMaxLine from 'src/components/text-max-line/text-max-line';
import PreviewAudio from 'src/components/upload/PreviewAudio';
import AppPermissions from 'src/constants/app-permissions';
import { useApproveUserRecordMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import RejectRecordDialog from 'src/sections/dialogs/edit/RejectRecordDialog';
import { UserRecordDtoType } from 'src/types/users-records';
import { getLocalizedValue } from 'src/utils/get-localized-value';

// ----------------------------------------------------------------------

type Props = {
  row: UserRecordDtoType;
};

export default function UsersRecordsRow({ row }: Props) {
  // #region States and hooks
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const isApproved = row.status === 'approved';
  const isRejected = row.status === 'rejected';
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === 'light';
  const confirmApprove = useBoolean();
  const rejectDialog = useBoolean();
  const isDisabled = !(row.status === 'pending');
  const chipColor =
    row.status === 'approved' ? 'success' : row.status === 'pending' ? 'warning' : 'error';
  // #endregion

  // #region Services
  const { mutate: approve, isLoading: isApproving } = useApproveUserRecordMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['UserRecords'] });
      confirmApprove.onFalse();
    },
  });

  // #endregion

  // #region Handlers
  const handleApprove = () => {
    approve({
      fileUrl: row.audioRecordUrl,
      mutoonSectionId: row.mutoonSection?.mutoonSectionId,
      name: { ar: row.user.firstName + ' ' + row.user.lastName },
      recordingId: row.recordingId,
    });
  };
  // #endregion

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row.user.firstName + ' ' + row.user.lastName}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          <Link
            style={{
              color: isLight ? 'black' : 'white',
            }}
            to={`${paths.dashboard.contentManagement.mutoon.matn}/${row.mutoonSection?.mutoon?.id}`}
          >
            {getLocalizedValue(row.mutoonSection?.mutoon?.name)}
          </Link>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          <Link
            style={{
              color: isLight ? 'black' : 'white',
            }}
            to={`${paths.dashboard.contentManagement.mutoon.matn}/${row.mutoonSection?.mutoon?.id}`}
          >
            <TextMaxLine line={6}>{getLocalizedValue(row.mutoonSection?.name)}</TextMaxLine>
          </Link>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>{row.user.email}</TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {moment(row.createdAt).format('YYYY-MM-DD')}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          <PreviewAudio src={row.audioRecordUrl!} />
        </TableCell>

        <TableCell>
          <Chip label={row.status} color={chipColor} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.USER_RECORD_EDIT]}>
            <Tooltip title={t('tableLabels.approve')} placement="bottom" arrow>
              <IconButton disabled={isDisabled} onClick={confirmApprove.onTrue}>
                <Iconify
                  icon="mdi:approve"
                  width="27"
                  height="27"
                  color={isDisabled ? 'gray' : '#FFAB8B'}
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.USER_RECORD_EDIT]}>
            <Tooltip title={t('tableLabels.reject')} placement="bottom" arrow>
              <IconButton
                disabled={isDisabled}
                LinkComponent={RouterLink}
                onClick={rejectDialog.onTrue}
              >
                <Iconify
                  icon="foundation:x"
                  width="27"
                  height="27"
                  color={isDisabled ? 'gray' : '#DDB6FF'}
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>

      <RejectRecordDialog open={rejectDialog.value} onClose={rejectDialog.onFalse} sound={row} />

      {/* Confirm approve */}
      <ConfirmDialog
        open={confirmApprove.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirmApprove.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: t('buttons.approve').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirmApprove.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label={t('publicWords.yes')}
              isLoading={isApproving}
              onClick={handleApprove}
            />
          </>
        }
      />
    </>
  );
}
