import { Checkbox, Collapse, IconButton, TableCell } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify/iconify';
import { TableProps, useTable } from 'src/components/table';
import { AppTablePropsType } from 'src/components/table/app-table';
import RowActions, { RowActionsPropsType } from 'src/components/table/table-row-actions';
import {
  HearingCouncilAnalysisQuery,
  useCreateCouncilCertificateMutation,
  useHearingCouncilAnalysisQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { default as UseSearch } from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import SchoolStudents from 'src/pages/dashboard/school-users/school-students/view/school-students';
import { FinishedCouncilsFormType } from 'src/types/hearing-council';
import { orderBy } from 'src/utils/order-by';
import { handleNoCertificatesSelected } from '../utils/helper';

type RowType = HearingCouncilAnalysisQuery['user_hearing_councils'][0];

const useFinishedHearingCouncilsSchoolsUsers = ({
  certificateHearingCouncilId,
}: {
  certificateHearingCouncilId: string;
}) => {
  // #region States and hooks
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const router = useNavigate();
  const { methods, query, handleSearch, setQuery } = UseSearch<FinishedCouncilsFormType>({});
  const [selectedRow, setSelectedRow] = useState<RowType>();
  const [index, setIndex] = useState<number>();
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const { hearingCouncilId } = useParams();
  const confirm = useBoolean();
  const confirmExportSelected = useBoolean();
  const [showStudentsSchool, setShowStudentsSchool] = useState<RowType>();

  const {
    fields: selectedUsers,
    append: selectUser,
    remove: unselectUser,
  } = useFieldArray({
    control: methods!?.control,
    name: 'selectedUsers',
  });
  // #endregion States and hooks

  // #region Services
  const { mutate: createCert, isLoading: isCreatingCert } = useCreateCouncilCertificateMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      confirmExportSelected.onFalse();
      queryClient.invalidateQueries({ queryKey: ['HearingCouncilAnalysis'] });
      queryClient.invalidateQueries({ queryKey: ['SchoolStudents'] });
      if (!data.createUserHearingCouncilCertificate.length) {
        enqueueSnackbar({
          message: t('tables.finishedHearingCouncilsAnalysis.createCertResWarning'),
          variant: 'warning',
        });
      }
    },
  });

  const { data, isLoading } = useHearingCouncilAnalysisQuery(
    {
      offset: table.offset,
      limit: table.rowsPerPage,
      order_by: orderBy(methods.watch().sortBy!, methods.watch().sortDirection),
      hearingCouncilId: hearingCouncilId!,
      search: query.search ? { _ilike: `%${query.search}%` } : undefined,
      user: { school_user: { user: { isSchool: { _eq: true } } } },
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
  // #endregion Services

  // #region Handlers
  console.log(selectedUsers);
  const handleExportSelectedCert = () => {
    if (!certificateHearingCouncilId) {
      handleNoCertificatesSelected(t);
    } else
      createCert({
        createCertficateInput: selectedUsers
          .map(
            (user) =>
              user.user?.school_user?.students
                .filter((student) => {
                  const isCertified = student.user_hearing_councils.find(
                    (uhc) => uhc.hearingCouncilId === hearingCouncilId
                  )?.user_certificate?.certificateId;
                  return !isCertified;
                })
                .map((student) => {
                  const userHearingCouncilId = student.user_hearing_councils.find(
                    (uhc) => uhc.hearingCouncilId === hearingCouncilId
                  )?.userHearingCouncilId;
                  return {
                    userHearingCouncilId: userHearingCouncilId!,
                    customName: student.firstName + ' ' + student.lastName,
                    certificateHearingCouncilId,
                  };
                })!
          )
          .flat()!,
      });
  };

  const handleExportUserCertificate = (index: number, row: RowType | undefined) => {
    if (row)
      createCert({
        createCertficateInput: row.user?.school_user?.students
          .filter((student) => {
            const isCertified = student.user_hearing_councils.find(
              (uhc) => uhc.hearingCouncilId === hearingCouncilId
            )?.user_certificate?.certificateId;
            return !isCertified;
          })
          .map((student) => ({
            userHearingCouncilId: student.user_hearing_councils.find(
              (uhc) => uhc.hearingCouncilId === hearingCouncilId
            )?.userHearingCouncilId,
            customName: student.firstName + ' ' + student.lastName,
            certificateHearingCouncilId,
            isPreview: false,
          })),
      });
  };

  const handleSelectAllRows = (checked: boolean, table: TableProps, rows: RowType[]) => {
    table.onSelectAllRows(
      checked,
      data!?.user_hearing_councils.map((user) => user.userHearingCouncilId)
    );
    if (!checked) {
      unselectUser();
    } else {
      // appendUser();
      selectUser(methods.watch().users.map((user, index) => ({ ...user })));
    }
  };

  const handleSelectRow = (row?: RowType, table?: TableProps, index?: number) => {
    table?.onSelectRow(row?.userHearingCouncilId!);
    if (!table?.selected.includes(row?.userHearingCouncilId!)) {
      selectUser({
        ...row!,
        customName: methods.watch().users[index!].customName,
      });
    } else {
      unselectUser(index);
    }
  };

  const handleCollapseRow = (row: RowType) => {
    if (row.userHearingCouncilId === showStudentsSchool?.userHearingCouncilId) {
      setShowStudentsSchool(undefined);
    } else {
      setShowStudentsSchool(row);
    }
  };
  // #endregion Handlers

  useEffect(() => {
    if (data) {
      methods.setValue(
        'users',
        data!?.user_hearing_councils.map((user) => ({
          ...user,
          customName: user.user?.firstName! + ' ' + user.user?.lastName!,
        }))
      );
    }
  }, [data]);

  const generateMenuOption = (row: RowType, index: number): RowActionsPropsType['options'] => {
    const userCertificate = row.user_certificate;
    const canBeCertified = !userCertificate?.id && row.hearingCouncil.status === 'Expired';

    return [
      {
        tooltip: '',
        icon: 'fluent:certificate-16-regular',
        onClick: !certificateHearingCouncilId
          ? () => handleNoCertificatesSelected(t)
          : () => {
              setSelectedRow(row);
              setIndex(index);
              confirm.onTrue();
            },
        permission: canBeCertified,
      },
    ].filter((option) => (option!?.permission === undefined ? option : option!?.permission));
  };

  const columns: AppTablePropsType<RowType>['columns'] = [
    {
      name: 'user.firstName',
      PreviewComponent: (data, index) => {
        return (
          <TableCell padding="checkbox">
            <IconButton
              size="small"
              onClick={() => {
                handleCollapseRow(data);
              }}
            >
              <Iconify
                icon={
                  showStudentsSchool?.userHearingCouncilId === data.userHearingCouncilId
                    ? 'eva:arrow-ios-upward-fill'
                    : 'eva:arrow-ios-downward-fill'
                }
              />
            </IconButton>
          </TableCell>
        );
      },
    },
    {
      name: 'user.firstName',
      PreviewComponent: (data, index) => {
        return <RHFTextField name={`users.${index}.customName`} />;
      },
    },
    {
      name: 'user.email',
    },
    {
      name: 'joinedAt',
      PreviewComponent: (data) => data.joinedAt && moment(data.joinedAt).format('YYYY-MM-DD'),
    },
    {
      name: 'durationAttendance',
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data) =>
        data.hearingCouncil.duration > 0
          ? `${
              +((+data.durationAttendance! / +data.hearingCouncil.duration) * 100).toFixed(2) || '-'
            }%`
          : '-',
    },
    {
      name: 'durationAttendance',

      PreviewComponent: (data) =>
        data.attendanceType
          ? t(
              `tables.finishedHearingCouncilsAnalysis.${
                data.attendanceType?.toLowerCase() as 'online'
              }`
            )
          : '',
    },
    {
      name: 'isFullyAttend',
      PreviewComponent: (data, index) => (data.isFullyAttend ? '✅' : '❌'),
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data, index) => <RowActions options={generateMenuOption(data, index)} />,
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data, index) => (
        <TableCell sx={{ py: 0 }} colSpan={12}>
          <Collapse
            in={data.userHearingCouncilId === showStudentsSchool?.userHearingCouncilId}
            unmountOnExit
          >
            <SchoolStudents
              userId={data.user?.id!}
              certificateHearingCouncilId={certificateHearingCouncilId}
            />
          </Collapse>
        </TableCell>
      ),
      collapsible: true,
    },
  ];

  const filters = [{}];

  const selectProps: AppTablePropsType<RowType>['select'] = {
    handleSelectRow: handleSelectRow!, // Not used here
    handleSelectAllRows: handleSelectAllRows!,
    numSelected: table.selected.length,
    rowCount: table.rowsPerPage,
    selectedRowsActions: [
      {
        icon: 'fluent:certificate-16-regular',
        color: '#FFAB8B',
        tooltip: 'Certify',
        handler: confirmExportSelected.onTrue,
      },
    ],
    idPath: 'userHearingCouncilId',
  };

  return {
    t,
    data,
    table: table,
    isLoading,
    isCreatingCert,
    columns: columns.filter((col) => col),
    filters,
    handleSearch,
    setQuery,
    query,
    methods,
    confirm,
    confirmExportSelected,
    handleSelectAllRows,
    handleExportSelectedCert,
    handleExportUserCertificate: () => handleExportUserCertificate(index!, selectedRow!),
    selectProps,
  };
};

export default useFinishedHearingCouncilsSchoolsUsers;
