import { Card, Stack, Typography } from '@mui/material';
import Chart from 'src/components/chart';
import useHearingCouncilsCharts from './use-hearing-councils-charts';
import moment from 'moment';
import { HearingCouncilsChartsPropsType } from './type';

const HearingCouncilsCharts = ({
  data,
  isLoading,
  hearingCouncilData,
}: HearingCouncilsChartsPropsType) => {
  const { CHARTS_INFO, CHART_SIZE, chartOptionsCheckIn, totalAttendance, t } =
    useHearingCouncilsCharts({ data, isLoading });

  const duration = hearingCouncilData?.hearing_councils_by_pk?.duration || 0;
  console.log(duration);
  console.log(moment(duration).hours());

  const durationObject = moment.duration(duration, 'minutes'); // Specify 'minutes' or 'seconds' based on your input
  const durationPreview = `${durationObject.hours()} ${t(
    'hearingCouncils.card.symbols.hours'
  )} ${durationObject.minutes()} ${t('hearingCouncils.card.symbols.minutes')}`;

  return (
    <Stack direction="row" spacing={2} my={2} sx={{ width: 1 }}>
      {CHARTS_INFO.map((chart) => (
        <Card sx={{ p: 1, flex: 1 }}>
          <Stack direction="row" spacing={1}>
            <Chart
              type="radialBar"
              series={[+((chart.value! / totalAttendance!) * 100).toFixed(1) || 0]}
              options={chartOptionsCheckIn}
              {...CHART_SIZE}
            />
            <Stack justifyContent="space-evenly">
              <Typography fontWeight="bold">{chart.value!}</Typography>
              <Typography>{chart.label!}</Typography>
            </Stack>
          </Stack>
        </Card>
      ))}
      <Card sx={{ p: 1, flex: 1 }}>
        <Stack spacing={1} justifyContent="space-evenly" sx={{ height: 1 }}>
          <Typography fontWeight="bold">{durationPreview}</Typography>
          <Typography>{t('hearingCouncils.analytics.actualDuration')}</Typography>
        </Stack>
      </Card>
    </Stack>
  );
};

export default HearingCouncilsCharts;
